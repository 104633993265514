/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* ALL PRODUCTS */
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS"
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS"
export const GET_ALL_PRODUCTS_FAIL = "GET_ALL_PRODUCTS_FAIL"

/* DELETED_PRODUCTS */
export const GET_DELETED_PRODUCTS = "GET_DELETED_PRODUCTS"
export const GET_DELETED_PRODUCTS_SUCCESS = "GET_DELETED_PRODUCTS_SUCCESS"
export const GET_DELETED_PRODUCTS_FAIL = "GET_DELETED_PRODUCTS_FAIL"

/* PRODUCT_DETAILS */
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS"
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS"
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL"

/* CREATE PRODUCT*/
export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS"
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL"

/* Edit PRODUCT*/
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"

/* Change PRODUCT status*/
export const CHANGE_PRODUCT_STATUS = "CHANGE_PRODUCT_STATUS"
export const CHANGE_PRODUCT_STATUS_SUCCESS = "CHANGE_PRODUCT_STATUS_SUCCESS"
export const CHANGE_PRODUCT_STATUS_FAIL = "CHANGE_PRODUCT_STATUS_FAIL"

/* Delete PRODUCT*/
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"

/* Hard Delete PRODUCT*/
export const HARD_DELETE_PRODUCT = "HARD_DELETE_PRODUCT"
export const HARD_DELETE_PRODUCT_SUCCESS = "HARD_DELETE_PRODUCT_SUCCESS"
export const HARD_DELETE_PRODUCT_FAIL = "HARD_DELETE_PRODUCT_FAIL"

/* Restore PRODUCT*/
export const RESTORE_PRODUCT = "RESTORE_PRODUCT"
export const RESTORE_PRODUCT_SUCCESS = "RESTORE_PRODUCT_SUCCESS"
export const RESTORE_PRODUCT_FAIL = "RESTORE_PRODUCT_FAIL"

/* Create Duplicate PRODUCT*/
export const CREATE_DUPLICATE_PRODUCT = "CREATE_DUPLICATE_PRODUCT"
export const CREATE_DUPLICATE_PRODUCT_SUCCESS =
  "CREATE_DUPLICATE_PRODUCT_SUCCESS"
export const CREATE_DUPLICATE_PRODUCT_FAIL = "CREATE_DUPLICATE_PRODUCT_FAIL"

export const UNDO_REFRASH = "UNDO_REFRASH"
