import React from "react"
import { map } from "lodash"
import Switch from "react-switch"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Badge, Button, Card, CardBody, CardSubtitle } from "reactstrap"
import { CardTitle, TabContent, TabPane } from "reactstrap"
import { Col, Container, Label, Row } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import FormEditors from "../Create/Editor"

//actions
import { getArea_lists, getProductDetails, updateProduct } from "store/actions"
import { getCategories } from "store/category/actions"
import { getSubCategories } from "store/subCategory/actions"
import { getAgeGroups } from "store/ageGroup/actions"
import { getBrands } from "store/brand/actions"
import { getGenderTypes } from "store/genderType/actions"

import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"
import bytesToSize from "common/useFileSize"

const UpdateProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const {
    productDetails,
    loading,
    categories,
    subCategories,
    ageGroups,
    genderTypes,
    brands,
  } = useSelector(state => ({
    productDetails: state.Products.productDetails,
    loading: state.Products.loading,
    categories: state.Category.categories,
    subCategories: state.SubCategory.subCategories,
    ageGroups: state.AgeGroup.ageGroups,
    genderTypes: state.GenderType.genderTypes,
    brands: state.Brand.brands,
  }))

  const { area } = useSelector(state => ({
    area: state.Area.areas,
  }))

  const [primaryLangSpecs, setPrimaryLangSpecs] = useState([
    {
      title: "",
      details: "",
    },
  ])
  const [secondaryLangSpecs, setSecondaryLangSpecs] = useState([
    {
      title: "",
      details: "",
    },
  ])

  const [images, setImages] = useState([])
  const [imagesPreview, setImagesPreview] = useState([])
  const [fileSize, setFileSize] = useState("")

  const [taxPercentage, setTaxPercentage] = useState(0)

  //baseData
  const [itemCode, setItemCode] = useState("")
  const [barcode, setBarcode] = useState("")
  const [primaryLangName, setPrimaryLangName] = useState("")
  const [primaryLangDescription, setPrimaryLangDescription] = useState("")
  const [secondaryLangName, setSecondaryLangName] = useState("")
  const [secondaryLangDescription, setSecondaryLangDescription] = useState("")
  const [primaryLangHighlights, setPrimaryLangHighlights] = useState("")
  const [secondaryLangHighlights, setSecondaryLangHighlights] = useState("")

  const [primaryKeywords, setPrimaryKeywords] = useState("")
  const [primaryKeywordsArray, setPrimaryKeywordsArray] = useState([])
  const [finalKeywordsArray, setFinalKeywordsArray] = useState([]) //final

  const [secondaryKeywords, setSecondaryKeywords] = useState([])
  const [secondaryKeywordsArray, setSecondaryKeywordsArray] = useState([])
  const [finalKeywordsArray2, setFinalKeywordsArray2] = useState([]) //final

  //classifications
  const [category, setCategory] = useState()
  const [subCategory, setSubCategory] = useState()
  const [genderType, setGenderType] = useState()
  const [ageGroup, setAgeGroup] = useState()
  const [brand, setBrand] = useState()

  //packageData
  const [weight, setWeight] = useState()
  const [areaList, setAreaList] = useState([])
  const [dimension, setDimension] = useState()
  const [packageSize, setPackageSize] = useState()
  const [isBulkyProduct, setIsBulkyProduct] = useState(false)

  //shippingData
  const [isExtraChargeNeeded, setIsExtraChargeNeeded] = useState(false)
  const [extraCharge, setExtraCharge] = useState()
  const [isAvailableEveryWhere, setIsAvailableEveryWhere] = useState(true)

  //fittingData
  const [fittingCharge, setFittingCharge] = useState()
  const [isFittingNeeded, setIsFittingNeeded] = useState(false)
  const [isFitted, setIsFitted] = useState(false)

  //labels
  const [isReturnViable, setisReturnViable] = useState(false)
  const [isFeatured, setisFeatured] = useState(false)
  const [extras, setExtras] = useState([])

  //values
  const [taxValue, setTaxValue] = useState(0)
  const [isDiscounted, setIsDiscounted] = useState(false)
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  const [price, setPrice] = useState(0)
  const [cost, setCost] = useState(0)
  const [profit, setProfit] = useState(0)
  const [sellingPrice, setSellingPrice] = useState(0)
  const [isTaxIncluded, setIsTaxIncluded] = useState(false)

  //stock
  const [stockValue, setStockValue] = useState(0)
  const [minStock, setMinStock] = useState(0)
  const [reorderQuantity, setReorderQuantity] = useState(0)

  const taxCategories = [0, 5, 8, 12, 15, 18, 28]

  const [activeTab, setActiveTab] = useState("1")

  const isMult = LanguageSwitch()?.isMult

  const primaryHighlights = productDetails?.primaryLang?.highlights
    ? productDetails?.primaryLang?.highlights
    : ""
  const secondaryHighlights = productDetails?.secondaryLang?.highlights
    ? productDetails?.secondaryLang?.highlights
    : ""

  useEffect(() => {
    const availableAreasNew = productDetails?.availableAreas?.map(i => i._id)

    setAreaList(availableAreasNew ? availableAreasNew : [])
  }, [productDetails])

  useEffect(() => {
    dispatch(getArea_lists(1))
  }, [dispatch])

  useEffect(() => {
    setPrimaryLangHighlights(primaryHighlights)
    setSecondaryLangHighlights(secondaryHighlights)
  }, [])

  console.log(productDetails)
  useEffect(() => {
    setPrimaryLangName(productDetails?.primaryLang?.name)
    setPrimaryLangDescription(productDetails?.primaryLang?.description)
    setPrimaryLangSpecs(productDetails?.primaryLang?.specs)
    setPrimaryKeywordsArray(productDetails?.primaryLang?.keywords)
    setPrimaryLangHighlights(primaryHighlights)
  }, [productDetails])

  useEffect(() => {
    setSecondaryLangName(productDetails?.secondaryLang?.name)
    setSecondaryLangDescription(productDetails?.secondaryLang?.description)
    setSecondaryLangSpecs(productDetails?.secondaryLang?.specs)
    setSecondaryKeywordsArray(productDetails?.secondaryLang?.keywords)
    setSecondaryLangHighlights(secondaryHighlights)
  }, [productDetails])

  useEffect(() => {
    const formatedArray = productDetails?.primaryLang?.keywords?.map(
      (i, key) => ({
        text: i,
        id: key,
      })
    )

    setPrimaryKeywordsArray(formatedArray)
    setFinalKeywordsArray(productDetails?.primaryLang?.keywords)
  }, [productDetails])

  useEffect(() => {
    const formatedArray = productDetails?.secondaryLang?.keywords?.map(
      (i, key) => ({
        text: i,
        id: key,
      })
    )

    setSecondaryKeywordsArray(formatedArray)
    setFinalKeywordsArray2(productDetails?.primaryLang?.keywords)
  }, [productDetails])

  useEffect(() => {
    setSubCategory(productDetails?.subCategory)
    setCategory(productDetails?.category?._id)
    setBrand(productDetails?.brand)
    setAgeGroup(productDetails?.ageGroup)
    setGenderType(productDetails?.genderType)
    setItemCode(productDetails?.itemCode)
    setBarcode(productDetails?.barcode)
    setImages(productDetails?.images)
    setCost(productDetails?.cost)
    setPrice(productDetails?.price)
    setProfit(productDetails?.profit)
    setSellingPrice(productDetails?.sellingPrice)
    setStockValue(productDetails?.stockValue)
    setReorderQuantity(productDetails?.reorderQuantity)
    setMinStock(productDetails?.minStock)
    setTaxValue(productDetails?.taxValue)
    setDiscountPercentage(productDetails?.discountPercentage)
    setDiscountValue(productDetails?.discountValue)
    setIsDiscounted(productDetails?.isDiscounted)
    setIsTaxIncluded(productDetails?.isTaxIncluded)
  }, [productDetails])

  useEffect(() => {
    setWeight(productDetails?.weight)
    setDimension(productDetails?.dimension)
    setPackageSize(productDetails?.packageSize)
    setIsBulkyProduct(productDetails?.isBulkyProduct)
    setIsFittingNeeded(productDetails?.isFittingNeeded)
    setIsFitted(productDetails?.isFitted)
    setFittingCharge(productDetails?.fittingCharge)
  }, [productDetails])

  useEffect(() => {
    setIsExtraChargeNeeded(productDetails?.isExtraChargeNeeded)
    setExtraCharge(productDetails?.extraCharge)
    setIsAvailableEveryWhere(productDetails?.isAvailableEveryWhere)
    setisReturnViable(productDetails?.isReturnViable)
  }, [productDetails])

  function handleValidSubmit(e, v) {
    const product = {
      images: images,
      itemCode: itemCode,
      barcode: barcode,
      // color: color,
      // size: size,
      primaryLang: {
        name: primaryLangName,
        description: primaryLangDescription,
        specs: primaryLangSpecs,
        highlights: primaryLangHighlights,
        keywords: finalKeywordsArray,
      },
      secondaryLang: {
        name: secondaryLangName,
        description: secondaryLangDescription,
        specs: secondaryLangSpecs,
        highlights: secondaryLangHighlights,
        keywords: finalKeywordsArray2,
      },

      //classifications
      category: category,
      subCategory: subCategory,
      genderType: genderType,
      ageGroup: ageGroup,
      brand: brand,
      images: images,

      //values
      price: price,
      cost: cost,
      profit: profit,
      sellingPrice: sellingPrice,
      // TODO:
      isTaxIncluded: isTaxIncluded,
      taxValue: taxValue,
      // TODO:
      isDiscounted: isDiscounted,
      discountPercentage: discountPercentage,
      discountValue: discountValue,

      //stock
      stockValue: stockValue,
      minStock: minStock,
      reorderQuantity: reorderQuantity,

      //labels
      isReturnViable: isReturnViable,
      isFeatured: isFeatured,
      extras: extras,

      // packageData
      weight: weight,
      dimension: dimension,
      packageSize: packageSize,
      isBulkyProduct: isBulkyProduct,

      // shippingData
      isExtraChargeNeeded: isExtraChargeNeeded,
      extraCharge: extraCharge,
      isAvailableEveryWhere: isAvailableEveryWhere,
      availableAreas: areaList,

      // fittingData
      isFittingNeeded: isFittingNeeded,
      isFitted: isFitted,
      fittingCharge: fittingCharge,
    }
    console.log("product:", product)
    dispatch(updateProduct(product, params.id, history))
  }

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)
    const { size, sizeInKb } = bytesToSize(e.target.files[0]?.size)

    setImagesPreview([])
    setImages([])
    if (sizeInKb >= 2000) {
      setFileSize(size + " file size is too large - Max Limit 2MB")
    } else {
      setFileSize("")

      files.forEach(file => {
        const reader = new FileReader()

        reader.onload = () => {
          if (reader.readyState === 2) {
            setImagesPreview(oldArray => [
              ...oldArray,
              { image: reader.result, size: size },
            ])
            setImages(oldArray => [...oldArray, reader.result])
          }
        }

        reader.readAsDataURL(file)
      })
    }
  }
  const addOrRemoveItem = (arr, setArray, value) => {
    console.log("data :", arr)
    const isPresent = arr.includes(value)
    var newArr = arr
    console.log("is present: ", isPresent)

    if (isPresent) {
      newArr = arr.filter(item => {
        return item.toString() !== value.toString()
      })
    } else {
      newArr = [...arr, value]
    }
    setArray(newArr)
    console.log("newArr : ", newArr)
  }
  /////
  const costChange = v => {
    setCost(v)
    const sellPriceCalc = Number(price) - Number(discountValue)
    // Number(price) + Number(taxValue) - Number(discountValue)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - v
    setProfit(profitValue)
  }

  const priceChange = v => {
    setPrice(v)
    const discoutValueCalc = (discountPercentage / 100) * v
    setDiscountValue(discoutValueCalc)
    const taxValueCalc = (taxPercentage / 100) * v
    setTaxValue(taxValueCalc)
    const sellPriceCalc = Number(v) - Number(discoutValueCalc)
    // Number(v) + Number(taxValueCalc) - Number(discoutValueCalc)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - cost
    setProfit(profitValue)
  }

  useEffect(() => {
    const taxValueCalc = sellingPrice * (taxPercentage / (taxPercentage + 100))
    const formatedTax = taxValueCalc?.toFixed(2)
    setTaxValue(formatedTax)
  }, [sellingPrice])

  const taxPercentageChange = v => {
    const tax = parseInt(v)

    setTaxPercentage(tax)
    // const taxValueCalc = (tax/ 100) * price
    const taxValueCalc = sellingPrice * (tax / (tax + 100))
    const formatedTax = taxValueCalc?.toFixed(2)
    setTaxValue(formatedTax)

    // const sellPriceCalc =
    //   Number(price) + Number(taxValueCalc) - Number(discountValue)
    // setSellingPrice(sellPriceCalc)
    // const profitValue = sellPriceCalc - cost
    // setProfit(profitValue)
  }

  // const taxValueChange = (v) =>{
  //   console.log('taxValue',v);
  //   setTaxValue(v);
  //   setTaxPercentage((v*100)/price);
  //   console.log('tax%: ',taxPercentage);
  // }

  const discountPercentageChange = v => {
    setDiscountPercentage(v)
    const discoutValueCalc = (v / 100) * price
    setDiscountValue(discoutValueCalc)
    const sellPriceCalc = Number(price) - Number(discoutValueCalc)
    // Number(price) + Number(taxValue) - Number(discoutValueCalc)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - cost
    setProfit(profitValue)
  }

  const discountValueChange = v => {
    setDiscountValue(v)
    setDiscountPercentage((v * 100) / price)
    const sellPriceCalc = Number(price) - Number(v)
    // Number(price) + Number(taxValue) - Number(v)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - cost
    setProfit(profitValue)
  }

  ///

  useEffect(() => {
    dispatch(getProductDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getSubCategories())
    dispatch(getAgeGroups())
    dispatch(getBrands())
    dispatch(getGenderTypes())
  }, [dispatch])

  const handleKeyWordLabel = () => {
    if (primaryKeywords?.length >= 1) {
      setPrimaryKeywordsArray([
        ...primaryKeywordsArray,
        { text: primaryKeywords, id: primaryKeywordsArray?.length + 1 },
      ])
      setFinalKeywordsArray([...finalKeywordsArray, primaryKeywords])
    }
    setPrimaryKeywords("")
  }

  const handleDeleteKeyword = id => {
    setPrimaryKeywordsArray(primaryKeywordsArray?.filter(i => i.id !== id))
  }

  useEffect(() => {
    const newKey = primaryKeywordsArray?.map(i => i.text)
    setFinalKeywordsArray(newKey)
  }, [primaryKeywordsArray])

  const handleKeyWordLabel2 = () => {
    if (secondaryKeywords?.length >= 1) {
      setSecondaryKeywordsArray([
        ...secondaryKeywordsArray,
        { text: secondaryKeywords, id: secondaryKeywordsArray?.length + 1 },
      ])
      setFinalKeywordsArray2([...finalKeywordsArray2, secondaryKeywords])
    }
    setSecondaryKeywords("")
  }

  const handleDeleteKeyword2 = id => {
    setSecondaryKeywordsArray(secondaryKeywordsArray?.filter(i => i.id !== id))
  }

  useEffect(() => {
    const newKey = secondaryKeywordsArray?.map(i => i.text)
    setFinalKeywordsArray2(newKey)
  }, [secondaryKeywordsArray])

  const validateName = v => {
    let name = v.target.value
    name = name.replace(/-/g, "")
    name = name.replace("/", "")
    v.target.value = name

    setPrimaryLangName(name)
  }

  return (
    <>
      <MetaTag title="Update Product" />

      <div className="page-content">
        <Breadcrumbs title="Products" breadcrumbItem="Update Product" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4 "
                        style={{ color: "#1e81b0" }}
                      >
                        Base Data
                      </CardTitle>
                      <Row>
                        <Col className="col-6 d-flex flex-wrap">
                          <div className="col-4 mb-3">
                            <AvField
                              name="itemCode"
                              label="Item Code"
                              type="text"
                              onChange={v => {
                                setItemCode(v.target.value)
                              }}
                              value={itemCode}
                              readOnly
                            />
                          </div>

                          <div className="col-4 mb-3 mx-3">
                            <AvField
                              name="barcode"
                              label="Barcode"
                              type="text"
                              onChange={v => {
                                setBarcode(v.target.value)
                              }}
                              value={barcode ? barcode : ""}
                            />
                          </div>
                        </Col>

                        {/* images */}
                        <Col className="col-12 d-flex flex-wrap">
                          <div className="form-group">
                            <label>Images</label>

                            <div className="mt-2 custom-file">
                              <input
                                type="file"
                                name="product_images"
                                className="custom-file-input"
                                id="customFile"
                                onChange={onChangeImageHandler}
                                multiple
                                style={{ cursor: "pointer" }}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFile"
                                style={{ cursor: "pointer" }}
                              >
                                Choose Images
                              </label>
                            </div>

                            {productDetails?.images?.map(img => (
                              <img
                                src={img?.url}
                                key={img._id}
                                alt="Images Preview"
                                className="m-1 P-2 mr-2"
                                width="20%"
                              />
                            ))}

                            {imagesPreview?.map((img, key) => (
                              <div key={key}>
                                <img
                                  src={img.image}
                                  alt="Images Preview"
                                  className="m-1 P-2 mr-2"
                                  width="20%"
                                />
                                <p>{img?.size}</p>
                              </div>
                            ))}
                            {fileSize && (
                              <p className="text-danger">{fileSize}</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row xl="12">
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    label="Name"
                                    type="text"
                                    onKeyUp={v => validateName(v)}
                                    errorMessage="Invalid product name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      productDetails?.primaryLang &&
                                      productDetails?.primaryLang?.name
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                    onChange={v => {
                                      setPrimaryLangDescription(v.target.value)
                                    }}
                                    value={
                                      productDetails?.primaryLang &&
                                      productDetails?.primaryLang?.description
                                    }
                                  />
                                </div>

                                {/* specs */}
                                <div className="col-12 mb-4">
                                  <div className="d-flex align-items-center justify-content-start">
                                    <Label
                                      htmlFor="lbltype"
                                      className="form-label"
                                    >
                                      Specifications
                                    </Label>
                                    <button
                                      className="mx-2 btn bg-light d-flex align-items-center justify-content-center"
                                      onClick={e => {
                                        e.preventDefault()
                                        setPrimaryLangSpecs(currentSpec => [
                                          ...currentSpec,
                                          {
                                            title: "",
                                            details: "",
                                          },
                                        ])
                                      }}
                                    >
                                      <i
                                        className="bx bx-plus"
                                        style={{ fontSize: "20px" }}
                                      ></i>
                                    </button>
                                  </div>

                                  <Col md="12" className=" mt-4">
                                    {primaryLangSpecs?.map((s, index) => {
                                      return (
                                        <div key={index}>
                                          <Row>
                                            <Col>
                                              <AvField
                                                name="title"
                                                type="text"
                                                value={s.title}
                                                placeholder="Title"
                                                onChange={e => {
                                                  const title = e.target.value
                                                  setPrimaryLangSpecs(
                                                    currentSpec =>
                                                      currentSpec.map(x =>
                                                        x === s
                                                          ? { ...x, title }
                                                          : x
                                                      )
                                                  )
                                                }}
                                              />
                                            </Col>

                                            <Col className="db__column">
                                              <AvField
                                                name="title"
                                                type="text"
                                                value={s.details}
                                                placeholder="Details"
                                                onChange={e => {
                                                  const details = e.target.value
                                                  setPrimaryLangSpecs(
                                                    currentPriceType =>
                                                      currentPriceType.map(x =>
                                                        x === s
                                                          ? { ...x, details }
                                                          : x
                                                      )
                                                  )
                                                }}
                                              />
                                            </Col>

                                            <Col>
                                              <button
                                                className="mx-2 btn"
                                                onClick={e => {
                                                  e.preventDefault()
                                                  setPrimaryLangSpecs(
                                                    currentSpec =>
                                                      currentSpec.filter(
                                                        x => x !== s
                                                      )
                                                  )
                                                }}
                                              >
                                                <i
                                                  className="bx bx-trash"
                                                  style={{ fontSize: "20px" }}
                                                ></i>
                                              </button>
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                    })}
                                  </Col>
                                </div>

                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label"
                                  >
                                    Keywords
                                  </Label>
                                  <div className="col-6 d-flex">
                                    <AvField
                                      name="primaryLang.keyword"
                                      label=""
                                      type="text"
                                      placeholder="Enter keywords"
                                      onChange={v => {
                                        setPrimaryKeywords(v.target.value)
                                      }}
                                      value={primaryKeywords}
                                    />
                                    <Button
                                      className="mx-2 px-2"
                                      onClick={handleKeyWordLabel}
                                    >
                                      Add Keywords
                                    </Button>
                                  </div>
                                  <div className="d-flex flex-wrap mt-3 mb-3">
                                    {map(primaryKeywordsArray, (item, key) => (
                                      <Badge
                                        key={key}
                                        className={
                                          "font-size-12 me-2 p-2 badge-soft-info mt-2 d-flex align-items-center justify-content-center "
                                        }
                                        style={{
                                          width: "fit-content",
                                          // background: "#87CEFA",
                                          // color: "#0008",
                                        }}
                                        pill
                                      >
                                        {item?.text}
                                        <i
                                          className="bx bx-x mx-2 bx-tada-hover"
                                          style={{
                                            fontSize: "1.2rem",
                                            cursor: "pointer",
                                            color: "#ff0000ab",
                                          }}
                                          onClick={() =>
                                            handleDeleteKeyword(item?.id)
                                          }
                                        ></i>
                                      </Badge>
                                    ))}
                                  </div>
                                </div>

                                {/* Highlights */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label"
                                  >
                                    Highlights
                                  </Label>
                                  <Col sm="12" className="mt-4">
                                  {primaryLangHighlights?.length >= 1 && (
                                      <FormEditors
                                        content={primaryLangHighlights}
                                        setContent={setPrimaryLangHighlights}
                                      />
                                    )}
                                    {primaryLangHighlights?.length <= 0 && (
                                      <FormEditors
                                        content={primaryLangHighlights}
                                        setContent={setPrimaryLangHighlights}
                                      />
                                    )}
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Name</CardSubtitle>
                                    <CardSubtitle>اسم</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.name"
                                    label=""
                                    type="text"
                                    onChange={v => {
                                      setSecondaryLangName(v.target.value)
                                    }}
                                    value={productDetails?.secondaryLang?.name}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Description</CardSubtitle>
                                    <CardSubtitle>وصف</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.description"
                                    label=""
                                    type="textarea"
                                    onChange={v => {
                                      setSecondaryLangDescription(
                                        v.target.value
                                      )
                                    }}
                                    rows="7"
                                    value={
                                      productDetails?.secondaryLang?.description
                                    }
                                  />
                                </div>
                                {/* Specs */}
                                <div className="col-12 mb-4">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>
                                      <div className="d-flex align-items-center justify-content-start">
                                        Specifications{" "}
                                        <button
                                          className="mx-2 btn bg-light d-flex align-items-center justify-content-center"
                                          onClick={e => {
                                            e.preventDefault()
                                            setSecondaryLangSpecs(
                                              currentSpec => [
                                                ...currentSpec,
                                                {
                                                  title: "",
                                                  details: "",
                                                },
                                              ]
                                            )
                                          }}
                                        >
                                          <i
                                            className="bx bx-plus"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </button>
                                      </div>
                                    </CardSubtitle>
                                    <CardSubtitle>تحديد</CardSubtitle>
                                  </div>

                                  <Col md="12" className="mt-4">
                                    {secondaryLangSpecs?.map((s, index) => {
                                      return (
                                        <div key={index}>
                                          <Row>
                                            <Col>
                                              <AvField
                                                name="title"
                                                type="text"
                                                value={s.title}
                                                placeholder="Title"
                                                onChange={e => {
                                                  const title = e.target.value
                                                  setSecondaryLangSpecs(
                                                    currentSpec =>
                                                      currentSpec.map(x =>
                                                        x === s
                                                          ? { ...x, title }
                                                          : x
                                                      )
                                                  )
                                                }}
                                              />
                                            </Col>

                                            <Col className="db__column">
                                              <AvField
                                                name="title"
                                                type="text"
                                                value={s.details}
                                                placeholder="Details"
                                                onChange={e => {
                                                  const details = e.target.value
                                                  setSecondaryLangSpecs(
                                                    currentPriceType =>
                                                      currentPriceType.map(x =>
                                                        x === s
                                                          ? { ...x, details }
                                                          : x
                                                      )
                                                  )
                                                }}
                                              />
                                            </Col>

                                            <Col>
                                              <button
                                                className="mx-2 btn"
                                                onClick={e => {
                                                  e.preventDefault()
                                                  setSecondaryLangSpecs(
                                                    currentSpec =>
                                                      currentSpec.filter(
                                                        x => x !== s
                                                      )
                                                  )
                                                }}
                                              >
                                                <i
                                                  className="bx bx-trash"
                                                  style={{ fontSize: "20px" }}
                                                ></i>
                                              </button>
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                    })}
                                  </Col>
                                </div>

                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Keywords</CardSubtitle>
                                    <CardSubtitle>الكلمات الدالة</CardSubtitle>
                                  </div>
                                  <div className="col-6 d-flex">
                                    <AvField
                                      name="primaryLang.keyword"
                                      label=""
                                      type="text"
                                      placeholder="Enter keywords"
                                      onChange={v => {
                                        setSecondaryKeywords(v.target.value)
                                      }}
                                      value={secondaryKeywords}
                                    />
                                    <Button
                                      className="mx-2 px-2"
                                      onClick={handleKeyWordLabel2}
                                    >
                                      Add Keywords
                                    </Button>
                                  </div>
                                  <div className="d-flex flex-wrap mt-3 mb-3">
                                    {map(
                                      secondaryKeywordsArray,
                                      (item, key) => (
                                        <Badge
                                          key={key}
                                          className={
                                            "font-size-12 me-2 p-2 badge-soft-secondary mt-2 d-flex align-items-center justify-content-center "
                                          }
                                          style={{ width: "fit-content" }}
                                          pill
                                        >
                                          {item?.text}
                                          <i
                                            className="bx bx-x mx-2 bx-tada-hover"
                                            style={{
                                              fontSize: "1.2rem",
                                              cursor: "pointer",
                                              color: "#ff000091",
                                            }}
                                            onClick={() =>
                                              handleDeleteKeyword2(item?.id)
                                            }
                                          ></i>
                                        </Badge>
                                      )
                                    )}
                                  </div>
                                </div>

                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Highlights</CardSubtitle>
                                    <CardSubtitle>يسلط الضوء</CardSubtitle>
                                  </div>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={secondaryLangHighlights}
                                      setContent={setSecondaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* classifications */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"
                        style={{ color: "#1e81b0" }}
                      >
                        Classifications
                      </CardTitle>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="category"
                                  label="Category"
                                  onChange={v => {
                                    setCategory(v.target.value)
                                  }}
                                  errorMessage="Select a category"
                                >
                                  {categories?.length < 1 && (
                                    <option>Select a category</option>
                                  )}
                                  {categories?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.primaryLang?.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="subCategory"
                                  label="Sub Category"
                                  onChange={v => {
                                    setSubCategory(v.target.value)
                                  }}
                                >
                                  {subCategories?.length < 1 && (
                                    <option>Select a SubCategory</option>
                                  )}
                                  {subCategories?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.primaryLang?.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                              {/* 
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="brand"
                                  label="Brand"
                                  onChange={v => {
                                    setBrand(v.target.value)
                                  }}
                                >
                                  {brands?.length < 1 && (
                                    <option>Select a Brand</option>
                                  )}
                                  {brands?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.primaryLang?.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>

                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="ageGroup"
                                  label="Age Group"
                                  onChange={v => {
                                    setAgeGroup(v.target.value)
                                  }}
                                >
                                  {ageGroups?.length < 1 && (
                                    <option>Select a Age Group</option>
                                  )}
                                  {ageGroups?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.primaryLang?.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>

                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="genderType"
                                  label="Gender Type"
                                  onChange={v => {
                                    setGenderType(v.target.value)
                                  }}
                                >
                                  {genderTypes?.length < 1 && (
                                    <option>Select a Gender Type</option>
                                  )}
                                  {genderTypes?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.primaryLang?.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div> */}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* values */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"
                        style={{ color: "#1e81b0" }}
                      >
                        Values
                      </CardTitle>
                      <Row>
                        <Col xl="6">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap ">
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  name="cost"
                                  label="Cost"
                                  type="number"
                                  value={cost}
                                  onChange={v => {
                                    costChange(v.target.value)
                                  }}
                                />
                              </div>
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  name="price"
                                  label="Price"
                                  type="number"
                                  value={price}
                                  onChange={v => {
                                    priceChange(v.target.value)
                                  }}
                                />
                              </div>
                            </Col>

                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-6 mb-3 mx-3">
                                <Label check for="checkbox">
                                  Is Tax included :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsTaxIncluded(v)
                                  }}
                                  checked={
                                    isTaxIncluded ? isTaxIncluded : false
                                  }
                                />
                              </div>
                            </Col>

                            {isTaxIncluded && (
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    type="select"
                                    name="taxCategory"
                                    label="Tax Category"
                                    onChange={v => {
                                      taxPercentageChange(v.target.value)
                                    }}
                                  >
                                    <option>Select a Tax category</option>
                                    {taxCategories?.map((item, key) => (
                                      <option key={key} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </AvField>
                                </div>
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="taxValue"
                                    label="Tax Value"
                                    type="number"
                                    value={taxValue}
                                    readOnly
                                  />
                                </div>
                              </Col>
                            )}

                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-6 mb-3 mx-3">
                                <Label check for="checkbox">
                                  {" "}
                                  Is Discounted :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsDiscounted(v)
                                  }}
                                  checked={isDiscounted ? isDiscounted : false}
                                />
                              </div>
                            </Col>

                            {isDiscounted && (
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="discountPercentage"
                                    label="Discount %"
                                    type="number"
                                    value={discountPercentage}
                                    onChange={v => {
                                      discountPercentageChange(v.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="discountValue"
                                    label="Discount Value"
                                    type="number"
                                    value={discountValue}
                                    onChange={v => {
                                      discountValueChange(v.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                            )}

                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  name="sellingPrice"
                                  label="Selling Price"
                                  type="number"
                                  value={sellingPrice}
                                  readOnly
                                />
                              </div>
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  name="profit"
                                  label="Profit"
                                  type="number"
                                  value={profit}
                                  readOnly
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="6">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap ">
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  name="stockValue"
                                  label="Stock"
                                  type="number"
                                  onChange={v => {
                                    setStockValue(v.target.value)
                                  }}
                                  value={stockValue}
                                />
                              </div>
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  name="minStock"
                                  label="Min Stock"
                                  type="number"
                                  onChange={v => {
                                    setMinStock(v.target.value)
                                  }}
                                  value={minStock}
                                />
                              </div>
                            </Col>
                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  name="reorderQuantity"
                                  label="Reorder Quantity"
                                  type="number"
                                  onChange={v => {
                                    setReorderQuantity(v.target.value)
                                  }}
                                  value={reorderQuantity}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* packageData */}
              <Row>
                <Col xl="6">
                  <Card>
                    <CardBody style={{ minHeight: "200px" }}>
                      <CardTitle
                        className="h4 mb-4"
                        style={{ color: "#1e81b0" }}
                      >
                        Package Details
                      </CardTitle>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-3 mb-3">
                                <AvField
                                  name="weight"
                                  label="Weight"
                                  type="number"
                                  onChange={v => {
                                    setWeight(v.target.value)
                                  }}
                                  value={weight}
                                />
                              </div>
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="dimension"
                                  label="Dimension"
                                  type="text"
                                  onChange={v => {
                                    setDimension(v.target.value)
                                  }}
                                  value={dimension}
                                />
                              </div>
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="packageSize"
                                  label="Package Size"
                                  onChange={v => {
                                    setPackageSize(v.target.value)
                                  }}
                                >
                                  <option>{packageSize}</option>
                                  {categories?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is Bulky Product :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsBulkyProduct(v)
                                  }}
                                  checked={
                                    isBulkyProduct ? isBulkyProduct : false
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {/* fittingData */}
                <Col xl="6">
                  <Card style={{ minHeight: "200px" }}>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"
                        style={{ color: "#1e81b0" }}
                      >
                        Fitting Details
                      </CardTitle>
                      <Row>
                        <div className="col-6 mb-3 align-items-center d-flex">
                          <Label check for="checkbox">
                            Is fitting needed :
                          </Label>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mx-3"
                            onColor="#626ed4"
                            onChange={v => {
                              setIsFittingNeeded(v)
                            }}
                            checked={isFittingNeeded ? isFittingNeeded : false}
                          />
                        </div>

                        <div className="col-4 mb-3 align-items-center d-flex ">
                          <Label check for="checkbox">
                            {" "}
                            Is fitted :
                          </Label>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mx-3"
                            onColor="#626ed4"
                            onChange={v => {
                              setIsFitted(v)
                            }}
                            checked={isFitted ? isFitted : false}
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <AvField
                            name="fittingCharge"
                            placeholder="Fitting charge"
                            type="number"
                            onChange={v => {
                              setFittingCharge(v.target.value)
                            }}
                            value={fittingCharge}
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* shippingData */}
              <Row>
                <Col xl="6">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"
                        style={{ color: "#1e81b0" }}
                      >
                        Shipping Details
                      </CardTitle>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-12 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is ReturnViable :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  value={isReturnViable}
                                  onChange={v => {
                                    setisReturnViable(v)
                                  }}
                                  checked={isReturnViable}
                                />
                              </div>
                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is extra charge needed :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsExtraChargeNeeded(v)
                                  }}
                                  checked={
                                    isExtraChargeNeeded
                                      ? isExtraChargeNeeded
                                      : false
                                  }
                                />
                              </div>
                              <div className="col-4 mb-3">
                                <AvField
                                  name="extraCharge"
                                  placeholder="Extra charge"
                                  type="number"
                                  onChange={v => {
                                    setExtraCharge(v.target.value)
                                  }}
                                  value={extraCharge}
                                />
                              </div>

                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  isAvailableEveryWhere :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsAvailableEveryWhere(v)
                                  }}
                                  checked={isAvailableEveryWhere}
                                />
                              </div>

                              {!isAvailableEveryWhere ? (
                                <Col sm="12" md="12" className="mb-3">
                                  <Label for="basicpill-cstno-input7">
                                    Area(multiple)
                                  </Label>
                                  <div className="d-flex  flex-wrap">
                                    {map(area, (item, key) => (
                                      <div key={key} className="m-1">
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name={item?.name}
                                          checked={areaList?.includes(
                                            item?._id
                                          )}
                                          id={item?._id}
                                          autoComplete="off"
                                          value={item?._id}
                                          onClick={e =>
                                            addOrRemoveItem(
                                              areaList,
                                              setAreaList,
                                              e.target.value
                                            )
                                          }
                                        />
                                        <label
                                          className="btn btn-outline-info"
                                          htmlFor={item?._id}
                                        >
                                          {item?.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {/* <div className="col-4 mb-3 align-items-center justify-content-between d-flex">
                                <Label check for="checkbox">
                                  Area :
                                </Label>
                                <AvField
                                  type="select"
                                  name="area"
                                  onChange={v => {
                                    setAreaList(v.target.value)
                                  }}
                                >
                                  <option>Select a Area</option>
                                  {area?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div> */}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateProduct

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}
