import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_DELETED_PRODUCTS,
  GET_PRODUCT_DETAILS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  CHANGE_PRODUCT_STATUS,
  DELETE_PRODUCT,
  HARD_DELETE_PRODUCT,
  RESTORE_PRODUCT,
  CREATE_DUPLICATE_PRODUCT,
} from "./actionTypes"
import {
  getProductsSuccess,
  getProductsFail,
  getAllProductsSuccess,
  getAllProductsFail,
  getDeletedProductsSuccess,
  getDeletedProductsFail,
  getProductDetailsSuccess,
  getProductDetailsFail,
  createProductFail,
  createProductSuccess,
  updateProductSuccess,
  updateProductFail,
  changeProductStatusSuccess,
  changeProductStatusFail,
  deleteProductSuccess,
  deleteProductFail,
  hardDeleteProductSuccess,
  hardDeleteProductFail,
  restoreProductSuccess,
  restoreProductFail,
  createDuplicateProductSuccess,
  createDuplicateProductFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import {
  errorNotification,
  Notification,
} from "../../components/Common/Notification"

function getAllProductsAPi({ page, sort, limit, searchText }) {
  if (searchText) {
    return get(
      `/product/admin/custom/${sort}?page=${
        page ? page : 1
      }&limit=${limit}&search=${searchText}`
    )
  } else {
    return get(
      `/product/admin/custom/${sort}?page=${page ? page : 1}&limit=${limit}`
    )
  }
}

function getDeletedProductsAPi() {
  return get("/product/admin/deleted/all")
}

const getProductDetailsAPi = productId => {
  return get(`/product/admin/single/${productId}`)
}

const createProductApi = ({ product }) => {
  return post("/product/admin/new", product)
}

const updateProductApi = ({ product, productId }) => {
  return ApiPut(`/product/admin/${productId}`, product)
}

const changeProductStatusApi = ({ productId }) => {
  return post(`/product/admin/status/${productId}`)
}

const deleteProductApi = productId => {
  return del(`/product/admin/${productId}`)
}

const hardDeleteProductApi = productId => {
  return del(`/product/admin/hard/${productId}`)
}

const restoreProductApi = productId => {
  return post(`/product/admin/restore/${productId}`)
}

const createDuplicateProductAPi = ({ productId }) => {
  return post(`product/admin/duplicate/${productId}`)
}

function* fetchProducts({ payload }) {
  try {
    const response = yield call(getProductsAPi, payload)
    yield put(getProductsSuccess(response))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* fetchAllProducts({ payload }) {
  try {
    const response = yield call(getAllProductsAPi, payload)
    yield put(getAllProductsSuccess(response))
  } catch (error) {
    yield put(getAllProductsFail(error))
  }
}

function* fetchDeletedProducts() {
  try {
    const response = yield call(getDeletedProductsAPi)
    yield put(getDeletedProductsSuccess(response))
  } catch (error) {
    yield put(getDeletedProductsFail(error))
  }
}

function* fetchProductDetails({ payload: productId }) {
  try {
    const response = yield call(getProductDetailsAPi, productId)

    yield put(getProductDetailsSuccess(response))
  } catch (error) {
    yield put(getProductDetailsFail(error))
  }
}

function* onCreateProduct({ payload }) {
  try {
    const response = yield call(createProductApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else {
      if (response) {
        Notification({
          type: "success",
          message: "product Created Successfully!",
          title: "",
        })
        payload.history.goBack()
        yield put(createProductSuccess(response))
      }
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createProductFail(error))
  }
}

function* onUpdateProduct({ payload }) {
  try {
    const response = yield call(updateProductApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "product Updated Successfully!",
        title: "",
      })
    }
    yield put(updateProductSuccess(response))

    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateProductFail(error))
  }
}

function* onChangeProductStatus({ payload }) {
  try {
    const response = yield call(changeProductStatusApi, payload)
    yield put(changeProductStatusSuccess(response))
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(changeProductStatusFail(error))
  }
}

function* onDeleteProduct({ productId, history }) {
  try {
    const response = yield call(deleteProductApi, productId)
    yield put(deleteProductSuccess(response))
    Notification({
      type: "success",
      message: "product Deleted Successfully!",
      title: "",
    })
    history.push("/products")
  } catch (error) {
    yield put(deleteProductFail(error))
  }
}

function* onHardDeleteProduct({ productId, history }) {
  try {
    const response = yield call(hardDeleteProductApi, productId)
    yield put(hardDeleteProductSuccess(response))
    Notification({
      type: "success",
      message: "product Hard Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(hardDeleteProductFail(error))
  }
}

function* onRestoreProduct({ productId, history }) {
  try {
    const response = yield call(restoreProductApi, productId)
    yield put(restoreProductSuccess(response))
    Notification({
      type: "success",
      message: "product Restored Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    yield put(restoreProductFail(error))
  }
}

function* onCreateDuplicateProduct({ payload }) {
  try {
    const response = yield call(createDuplicateProductAPi, payload)
    yield put(createDuplicateProductSuccess(response))
    Notification({
      type: "success",
      message: "product Duplicated Successfully, try refresh!",
      title: "",
    })
    payload.history.reload()
    window.location.reload()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createDuplicateProductFail(error))
  }
}

function* productSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(GET_ALL_PRODUCTS, fetchAllProducts)
  yield takeEvery(GET_DELETED_PRODUCTS, fetchDeletedProducts)
  yield takeEvery(GET_PRODUCT_DETAILS, fetchProductDetails)
  yield takeEvery(CREATE_PRODUCT, onCreateProduct)
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)
  yield takeEvery(CHANGE_PRODUCT_STATUS, onChangeProductStatus)
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(HARD_DELETE_PRODUCT, onHardDeleteProduct)
  yield takeEvery(RESTORE_PRODUCT, onRestoreProduct)
  yield takeEvery(CREATE_DUPLICATE_PRODUCT, onCreateDuplicateProduct)
}

export default productSaga

// function* fetchAllActiveProducts({ payload }) {
//   console.log("get all active")
//   try {
//     const response = yield call(getAllActiveProductsAPi, payload)
//     yield put(getAllActiveProductsSuccess(response))
//   } catch (error) {
//     yield put(getAllActiveProductsFail(error))
//   }
// }

// function* fetchAllInactiveProducts({ payload }) {
//   try {
//     const response = yield call(getAllInactiveProductsAPi, payload)
//     yield put(getAllInactiveProductsSuccess(response))
//   } catch (error) {
//     yield put(getAllInactiveProductsFail(error))
//   }
// }

// function* fetchAllDeletedProducts({ payload }) {
//   try {
//     const response = yield call(getAllDeletedProductsAPi, payload)
//     console.log("response: ", response)
//     yield put(getAllDeletedProductsSuccess(response))
//   } catch (error) {
//     yield put(getAllDeletedProductsFail(error))
//   }
// }
