import { map } from "lodash"
import Select from "react-select"
import { useHistory } from "react-router"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap"
import { Col, Container, FormGroup, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { TabContent, TabPane } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { createCollection, getAllProducts } from "store/actions"

import ImageInput from "components/Common/MyInput"
import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const CrateCollections = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, products } = useSelector(state => ({
    loading: state.Collection.loading,
    products: state.Products.products,
  }))

  const [logoImages, setLogoImages] = useState([])
  const [logoImagesPreview, setLogoImagesPreview] = useState([])

  const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])

  const [activeTab, setActiveTab] = useState("1")

  const [searchText, setSearchText] = useState("")
  const [selectedProduct, setSelectedProduct] = useState("Search a Product")
  const [seletedProdData, setSeletedProdData] = useState([])
  const [productIdsForApi, setProductIdsForApi] = useState([])

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", searchText))
  }, [searchText])

  function handleValidSubmit(values) {
    const createCollections = {
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      priority: parseInt(values.priority),
      logoImages: logoImages,
      bannerImages: bannerImages,
      products: productIdsForApi,
    }
    console.log(createCollections)
    dispatch(createCollection(createCollections, history))
  }

  const onChangeLogoImageHandler = e => {
    const files = Array.from(e.target.files)

    setLogoImagesPreview([])
    setLogoImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoImagesPreview(oldArray => [...oldArray, reader.result])
          setLogoImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onChangeBannerImageHandler = e => {
    const files = Array.from(e.target.files)

    setBannerImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBannerImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  //dropdown with search for product

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerProductFinalValue(event) {
    setSelectedProduct(event.label)
    setSeletedProdData([...seletedProdData, event])
    setProductIdsForApi([...productIdsForApi, event.value])
  }

  const productOptions = [
    {
      options:
        products?.length &&
        products?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
          images: result.images[0]?.url,
        })),
    },
  ]

  const handleRemoveSeletedproduct = id => {
    setSeletedProdData(seletedProdData.filter(i => i.value !== id))
  }

  const onDeleteImg = id => {
    setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  }
  const isMult = LanguageSwitch()?.isMult

  return (
    <>
      <MetaTag title="Create Collection" />

      <div className="page-content">
        <Breadcrumbs title="Collections" breadcrumbItem="Create Collection" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="4" lg="4">
                          <CardTitle
                            className="h4 mb-4"
                            style={{ color: "#1e81b0" }}
                          >
                            Priority
                          </CardTitle>
                          <div className="mb-3">
                            <AvField name="priority" label="" type="number" />
                          </div>
                        </Col>
                        <Col xl="4" lg="4" className="mb-3">
                          <CardTitle
                            className="h4 mb-4 "
                            style={{ color: "#1e81b0" }}
                          >
                            Products
                          </CardTitle>
                          <FormGroup className="mb-3">
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleEnters}
                                value={selectedProduct}
                                placeholder={"Select a Product"}
                                onChange={handlerProductFinalValue}
                                options={productOptions}
                                classNamePrefix="select-selection"
                                isLoading={true}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="12">
                  {seletedProdData?.length >= 1 && (
                    <Card>
                      <CardBody>
                        <CardTitle
                          className="h4 mb-2 "
                          style={{ color: "#1e81b0" }}
                        >
                          Selected Products
                        </CardTitle>
                        {map(seletedProdData, (item, key) => (
                          <div key={key} className="mb-2">
                            <div className="d-flex align-items-center">
                              <img
                                className="img-thumbnail rounded-circle "
                                style={{ height: "60px", width: "60px" }}
                                src={item.images}
                                alt="product"
                              />
                              <p className="pb-0 mb-0 px-3">{item.label}</p>
                              <i
                                className="bx bx-trash-alt "
                                style={{
                                  fontSize: "1rem",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveSeletedproduct(item.value)
                                }
                              ></i>
                            </div>
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  )}
                </Col>

                <Col xl="12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}

                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.title"
                                    className=""
                                    label="Name"
                                    type="text"
                                    errorMessage="Invalid Collection name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                  />
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col xl="12">
                              <Row>
                                <Col className="col-12 d-flex flex-wrap">
                                  <Col className="col-12 d-flex flex-wrap">
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Name</CardSubtitle>
                                        <CardSubtitle>اسم</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.title"
                                        label=""
                                        type="text"
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Description</CardSubtitle>
                                        <CardSubtitle>وصف</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.description"
                                        label=""
                                        type="textarea"
                                        rows="7"
                                      />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="form-group px-3">
                        <CardTitle className="mb-4">Banner images :</CardTitle>

                        <div className="custom-file d-flex justify-content-start align-items-center">
                          <input
                            type="file"
                            name="banner_images"
                            className="custom-file-input"
                            id="banner_input"
                            onChange={onChangeBannerImageHandler}
                            multiple
                            hidden
                          />
                          <ImageInput htmlFor="banner_input" />

                          <div className="mx-5 d-flex justify-content-end flex-wrap align-items-center">
                            {bannerImagesPreview?.map((img, index) => (
                              <div
                                style={{
                                  position: "relative",
                                }}
                                className="mb-2"
                                key={index}
                              >
                                <img
                                  src={img?.url}
                                  alt="Images Preview"
                                  className="mr-2"
                                  style={{ marginRight: ".5rem" }}
                                  width="75"
                                  height="72"
                                />
                                <i
                                  className="bx bx-x-circle"
                                  style={{
                                    color: "red",
                                    fontSize: "1.2rem",
                                    position: "absolute",
                                    top: "2px",
                                    right: "12px",
                                    background: "#e9e9ea",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => onDeleteImg(img?.id)}
                                ></i>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="form-group px-3 mt-5">
                        <CardTitle className="mb-4">Logo images :</CardTitle>

                        <div className="custom-file d-flex justify-content-start align-items-center">
                          <input
                            type="file"
                            name="product_images"
                            className="custom-file-input"
                            id="logo_input"
                            onChange={onChangeLogoImageHandler}
                            multiple
                            hidden
                          />
                          <ImageInput htmlFor="logo_input" />

                          <div className="mx-5">
                            {logoImagesPreview?.map(img => (
                              <img
                                src={img}
                                key={img}
                                alt="Images Preview"
                                className="mt-3 mr-2"
                                width="65"
                                height="62"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Collection
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CrateCollections
