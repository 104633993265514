import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_AGEGROUPS,
  CREATE_AGEGROUP,
  GET_AGEGROUP_DETAILS,
  DELETE_AGEGROUP,
  UPDATE_AGEGROUP,
} from "./actionTypes"
import {
  getAgeGroupsSuccess,
  getAgeGroupsFail,
  getAgeGroupDetailsSuccess,
  getAgeGroupDetailsFail,
  createAgeGroupFail,
  createAgeGroupSuccess,
  updateAgeGroupSuccess,
  updateAgeGroupFail,
  deleteAgeGroupSuccess,
  deleteAgeGroupFail,
} from "./actions"

//Include Both Helper File with needed methods
// import { getCategories, getProjectsDetails, addNewProject, updateProject, deleteProject } from "helpers/fakebackend_helper"
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function ageGroupApi() {
  return get("/ageGroup/admin/all")
}

const getAgeGroupDetailsAPi = ageGroupId => {
  return get(`/ageGroup/admin/${ageGroupId}`)
}

function createAgeGroupApi({ ageGroup }) {
  return post("/ageGroup/admin/new", ageGroup)
}

function updateAgeGroupApi({ ageGroup, ageGroupId }) {
  return ApiPut(`/ageGroup/admin/${ageGroupId}`, ageGroup)
}

function deleteAgeGroupApi(ageGroupId) {
  return del(`/ageGroup/admin/${ageGroupId}`)
}

function* fetchAgeGroups() {
  try {
    const response = yield call(ageGroupApi)
    yield put(getAgeGroupsSuccess(response))
  } catch (error) {
    yield put(getAgeGroupsFail(error))
  }
}

function* fetchAgeGroupDetails({ payload: ageGroupId }) {
  try {
    const response = yield call(getAgeGroupDetailsAPi, ageGroupId)
    yield put(getAgeGroupDetailsSuccess(response))
  } catch (error) {
    yield put(getAgeGroupDetailsFail(error))
  }
}

function* onCreateAgeGroup({ payload }) {
  try {
    const response = yield call(createAgeGroupApi, payload)
    yield put(createAgeGroupSuccess(response))
    Notification({
      type: "success",
      message: "AgeGroup Created Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(createAgeGroupFail(error))
  }
}

function* onUpdateAgeGroup({ payload }) {
  try {
    const response = yield call(updateAgeGroupApi, payload)
    yield put(updateAgeGroupSuccess(response))
    Notification({
      type: "success",
      message: "AgeGroup Updated Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(updateAgeGroupFail(error))
  }
}

function* onDeleteAgeGroup({ ageGroupId, history }) {
  try {
    const response = yield call(deleteAgeGroupApi, ageGroupId)
    yield put(deleteAgeGroupSuccess(response))
    Notification({
      type: "error",
      message: "AgeGroup Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteAgeGroupFail(error))
  }
}

function* ageGroupSaga() {
  yield takeEvery(GET_AGEGROUPS, fetchAgeGroups)
  yield takeEvery(GET_AGEGROUP_DETAILS, fetchAgeGroupDetails)
  yield takeEvery(CREATE_AGEGROUP, onCreateAgeGroup)
  yield takeEvery(UPDATE_AGEGROUP, onUpdateAgeGroup)
  yield takeEvery(DELETE_AGEGROUP, onDeleteAgeGroup)
}

export default ageGroupSaga
