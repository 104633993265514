import React, { useEffect } from "react"
import { Card, CardBody, Col, Label, Row } from "reactstrap"

//actions
import { getRevenues } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { map } from "lodash"
import { CurrencySwitch } from "common/Currency"

const GrossRevenues = props => {
  const dispatch = useDispatch()

  const { totalRevenueData } = useSelector(state => ({
    totalRevenueData: state.Revenues.revenues.totalRevenueData,
  }))

  const currency = CurrencySwitch()

  useEffect(() => {
    dispatch(getRevenues())
  }, [dispatch])

  const revenueData = [
    //orders
    {
      id: "totalOrders",
      title: "Total Orders",
      value: totalRevenueData?.totalOrders,
      iconClass: "bx bx-shopping-bag",
    },
    {
      id: "totalPendingProducts",
      title: "Pending Orders",
      value: totalRevenueData?.totalPendingProducts,
      iconClass: "fas fa-shopping-bag",
    },
    {
      id: "totalCancelledProductsCount",
      title: "Cancelled Orders",
      value: totalRevenueData?.totalCancelledProductsCount,
      iconClass: "fas fa-stop",
    },
    {
      id: "totalOrderPrice",
      title: "Total amount from orders",
      value: totalRevenueData?.totalOrderPrice,
      iconClass: "bx bx-notepad",
      isSmall: true,
      needCurrency: true,
    },

    //payment

    {
      id: "totalCodPaymentCount",
      title: "Total COD Payments",
      value: totalRevenueData?.totalCodPaymentCount,
      iconClass: "fas fa-hand-holding-usd",
      isBig: true,
    },
    {
      id: "totalCodPaymentValue",
      title: "Total amount from COD payments",
      value: totalRevenueData?.totalCodPaymentValue,
      iconClass: "bx bx-mobile-alt",
      needCurrency: true,
    },
    {
      id: "totalOnlinePaymentCount",
      title: "Total Online Payments",
      value: totalRevenueData?.totalOnlinePaymentCount,
      iconClass: "bx bxs-dollar-circle",
      // isBig: true,
    },
    {
      id: "totalOnlinePaymentValue",
      title: "Total Amount of Online Payments",
      value: totalRevenueData?.totalOnlinePaymentValue,
      iconClass: "bx bxs-dollar-circle",
      needCurrency: true,
    },

    //price and tax
    {
      id: "totalCost",
      title: "Total Cost",
      value: totalRevenueData?.totalCost,
      iconClass: "bx bx-wifi-1",
      needCurrency: true,
    },
    {
      id: "totalSellingPrice",
      title: "Total SellingPrice",
      value: totalRevenueData?.totalSellingPrice,
      iconClass: "bx bx-wifi-0",
      needCurrency: true,
    },
    {
      id: "totalTaxValue",
      title: "Total TaxValue",
      value: totalRevenueData?.totalTaxValue,
      iconClass: "fas fa-file-invoice-dollar",
      needCurrency: true,
    },
    {
      id: "totalProfit",
      title: "Total Profit",
      value: totalRevenueData?.totalProfit,
      iconClass: "fas fa-file-invoice-dollar",
    },

    //price
    {
      id: "totalItemsPrice",
      title: "Total Items Price",
      value: totalRevenueData?.totalItemsPrice,
      iconClass: "bx bx-bookmarks",
      isBig: true,
      needCurrency: true,
    },
    {
      id: "totalPriceWithoutTax",
      title: "Total Price Without Tax",
      value: totalRevenueData?.totalPriceWithoutTax,
      iconClass: "bx bx-book-bookmark",
      // isBig: true,
      needCurrency: true,
    },

    {
      id: "totalShippingPrice",
      title: "Total ShippingPrice",
      value: totalRevenueData?.totalShippingPrice,
      iconClass: "bx bx-cart",
      isBig: true,
      needCurrency: true,
    },
    {
      id: "totalReturnedProductsPrice",
      title: "Total amount from returned order",
      value: totalRevenueData?.totalReturnedProductsPrice,
      iconClass: "bx bx-undo",
      needCurrency: true,
    },

    //order status
    {
      id: "totalProductItemsSold",
      title: "Total Sold Items",
      value: totalRevenueData?.totalProductItemsSold,
      iconClass: "bx bx-notepad",
    },
    {
      id: "totalDeliveredProductsCount",
      title: "Delivered Products",
      value: totalRevenueData?.totalDeliveredProductsCount,
      iconClass: "bx bx-list-ol",
    },

    {
      id: "totalReturnedProductsCount",
      title: "Returned Products",
      value: totalRevenueData?.totalReturnedProductsCount,
      iconClass: "bx bx-list-ol",
    },
    {
      id: "totalShippedProductsCount",
      title: "Shipped Products",
      value: totalRevenueData?.totalShippedProductsCount,
      iconClass: "bx bx-list-ol",
    },
    // {
    //   id: "totalProductsSoldQuantity",
    //   title: "Total Products Sold Quantity",
    //   value: totalRevenueData?.totalProductsSoldQuantity,
    //   iconClass: "bx bx-cart",
    // },

    // {
    //   id: "totalOrderProducts",
    //   title: "Total Ordered Products",
    //   value: totalRevenueData?.totalOrderProducts,
    //   iconClass: "bx bx-cart",
    // },
  ]

  return (
    <React.Fragment>
      <div>
        <Row>
          <Row>
            <Label>Statistics</Label>
          </Row>
          <Row>
            {map(revenueData, (item, key) => (
              <Col
                key={key}
                className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-3"
              >
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div
                        className="me-3"
                        style={{ width: item.isSmall && "60%" }}
                      >
                        <p
                          className={"text-muted mb-2"}
                          style={{ whiteSpace: item.isSmall && "nowrap" }}
                        >
                          {item.title}&nbsp;
                          {item.isBig && (
                            <span
                              className="text-white"
                              style={{ opacity: "0" }}
                            >
                              {item.title}
                            </span>
                          )}
                        </p>

                        <h5 className="mb-0">
                          <span className="text-muted">
                            {item.needCurrency && currency}
                          </span>
                          &nbsp;
                          {item.value}
                        </h5>
                      </div>

                      <div
                        className="avatar-sm ms-auto"
                        style={{ marginRight: item.isSmall && "-10px" }}
                      >
                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                          <i className={item.iconClass}></i>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default GrossRevenues
