import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { getBannerDetails, updateBanner } from "store/actions"
import { getBanners } from "store/banner/actions"
import MetaTag from "components/Common/Meta-tag"

const UpdateBanner = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { bannerDetails, loading } = useSelector(state => ({
    bannerDetails: state.Banner.bannerDetails,
    loading: state.Banner.loading,
  }))

  const types = ["main", "sub"]

  const [images, setImages] = useState([])
  const [oldImages, setOldImages] = useState([])
  const [imagesPreview, setImagesPreview] = useState([])

  function handleValidSubmit(values) {
    const updateBannerData = {
      tag: values.tag,
      priority: parseInt(values.priority),
      images: images,
      type: values.type,
      link: values.link,
    }

    console.log(updateBannerData)
    dispatch(updateBanner(updateBannerData, bannerDetails?._id, history))
  }

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)

    setImagesPreview([])
    setImages([])
    setOldImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview(oldArray => [...oldArray, reader.result])
          setImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    dispatch(getBannerDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getBanners())
  }, [dispatch])

  useEffect(() => {
    setImages(bannerDetails?.images)
    setOldImages(bannerDetails?.images)
  }, [bannerDetails])

  return (
    <>
      <MetaTag title={"Update Banners"} />

      <div className="page-content">
        <Breadcrumbs title="Banners" breadcrumbItem="Update Banner" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={8} md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      {/* Fill this form */}
                    </CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {/* {createBannerError && (
                        <Alert color="danger">{createBannerError}</Alert>
                      )} */}
                      <Row>
                        <Col className="col-12 d-flex flex-wrap">
                          <div className="col-4 mb-3 px-3">
                            <AvField
                              name="tag"
                              label="Tag"
                              type="text"
                              value={bannerDetails?.tag || ""}
                            />
                          </div>

                          <div className="col-4 mb-4 px-3">
                            <AvField
                              name="priority"
                              label="Priority"
                              type="number"
                              value={bannerDetails?.priority || ""}
                            />
                          </div>
                          <div className="col-4 mb-4 px-3">
                            <AvField
                              label="Type"
                              type="select"
                              name="type"
                              value={bannerDetails?.type || ""}
                              // errorMessage="Select a banner type"
                              //   validate={{
                              //     required: { value: true },
                              //   }}
                            >
                              <option>Select a banner type</option>
                              {types?.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            </AvField>
                          </div>

                          <div className="col-12 mb-4 px-3">
                            <AvField
                              name="link"
                              label="Link"
                              type="text"
                              value={bannerDetails?.link || ""}
                            />
                          </div>

                          <div className="form-group mb-3 px-3">
                            <label>Banner Image</label>

                            <div className="custom-file">
                              <input
                                type="file"
                                name="product_images"
                                className="custom-file-input"
                                id="customFile"
                                onChange={onChangeImageHandler}
                                // multiple
                              />
                            </div>
                            <div>
                              {oldImages &&
                                oldImages.map(img => (
                                  <img
                                    key={img}
                                    src={img.url}
                                    alt={img.url}
                                    className="mt-3 mr-2"
                                    width="100%"
                                    height="100%"
                                  />
                                ))}

                              {imagesPreview.map(img => (
                                <img
                                  src={img}
                                  key={img}
                                  alt="Images Preview"
                                  className="mt-3 mr-2"
                                  width="100%"
                                  height="100%"
                                />
                              ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
                {/* <PreviewCard /> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateBanner
