import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { createBanner } from "store/actions"
import { getBanners } from "store/banner/actions"
import MetaTag from "components/Common/Meta-tag"
import bytesToSize from "common/useFileSize"

const CreateBanner = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading } = useSelector(state => ({
    loading: state.Banner.loading,
  }))

  const types = ["main", "sub"]

  const [imgRows, setImgRows] = useState([{ id: 1 }])
  const [images, setImages] = useState([])
  const [imagesPreview, setImagesPreview] = useState([])
  const [fileSize, setFileSize] = useState("")

  const onAddImgRow = () => {
    const modifiedRows = [...imgRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setImgRows(modifiedRows)
  }

  const onDeleteImgRow = id => {
    if (id !== 1) {
      var modifiedRows = [...imgRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setImgRows(modifiedRows)
    }
  }

  function handleValidSubmit(values) {
    const createBannerData = {
      tag: values.tag,
      priority: parseInt(values.priority),
      images: images,
      type: values.type,
      link: values.link,
    }
    console.log(createBannerData)
    dispatch(createBanner(createBannerData, history))
  }

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)

    const { size, sizeInKb } = bytesToSize(e.target.files[0]?.size)

    setImagesPreview([])
    setImages([])

    if (sizeInKb >= 2000) {
      setFileSize(size + " file size is too large - Max Limit 2MB")
    } else {
      setFileSize("")
      files.forEach(file => {
        const reader = new FileReader()

        reader.onload = () => {
          if (reader.readyState === 2) {
            setImagesPreview(oldArray => [
              ...oldArray,
              { image: reader.result, size: size },
            ])
            setImages(oldArray => [...oldArray, reader.result])
          }
        }

        reader.readAsDataURL(file)
      })
    }
  }

  useEffect(() => {
    dispatch(getBanners())
  }, [dispatch])

  return (
    <>
      <MetaTag title={"Create Banner"} />

      <div className="page-content">
        <Breadcrumbs title="Banners" breadcrumbItem="Create Banner" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={8} md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4"></CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {/* {createBannerError && (
                        <Alert color="danger">{createBannerError}</Alert>
                      )} */}
                      <Row>
                        <Col className="col-12 d-flex flex-wrap">
                          <div className="col-4 mb-4 px-3">
                            <AvField name="tag" label="Tag" type="text" />
                          </div>
                          <div className="col-4 mb-4 px-3">
                            <AvField
                              name="priority"
                              label="Priority"
                              type="number"
                            />
                          </div>
                          <div className="col-4 mb-4 px-3">
                            <AvField
                              label="Type"
                              type="select"
                              name="type"
                              errorMessage="Select a banner type"
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option>Select a banner type</option>
                              {types?.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            </AvField>
                          </div>

                          <div className="col-12 mb-4 px-3">
                            <AvField name="link" label="Link" type="text" />
                          </div>

                          {/* images */}
                          <div className="mb-3 px-3">
                            <label>Banner Image</label>

                            <div className="custom-file">
                              <input
                                type="file"
                                name="product_images"
                                className="custom-file-input"
                                id="customFile"
                                onChange={onChangeImageHandler}
                                // multiple
                              />
                            </div>
                            <div>
                              {imagesPreview.map((img, key) => (
                                <div key={key}>
                                  <img
                                    src={img.image}
                                    alt="Images Preview"
                                    className="mt-3  mr-2"
                                    width="100%"
                                    height="100%"
                                  />
                                  <p>{img?.size}</p>
                                </div>
                              ))}
                              {fileSize && (
                                <p className="text-danger">{fileSize}</p>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="m-3 text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
                {/* <PreviewCard /> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateBanner
