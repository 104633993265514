import {
  GET_AGEGROUPS,
  GET_AGEGROUPS_FAIL,
  GET_AGEGROUPS_SUCCESS,
  GET_AGEGROUP_DETAILS,
  GET_AGEGROUP_DETAILS_SUCCESS,
  GET_AGEGROUP_DETAILS_FAIL,
  CREATE_AGEGROUP_SUCCESS,
  CREATE_AGEGROUP_FAIL,
  UPDATE_AGEGROUP_SUCCESS,
  UPDATE_AGEGROUP,
  UPDATE_AGEGROUP_FAIL,
  DELETE_AGEGROUP_SUCCESS,
  DELETE_AGEGROUP_FAIL,
  CREATE_AGEGROUP,
} from "./actionTypes"

const INIT_STATE = {
  ageGroups: [],
  ageGroupDetails: {},
  error: {},
  loading: false,
}

const AgeGroup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGEGROUPS:
    case GET_AGEGROUP_DETAILS:
    case UPDATE_AGEGROUP:
    case CREATE_AGEGROUP:
      return {
        ...state,
        loading: true,
      }
    case GET_AGEGROUPS_SUCCESS:
      return {
        ...state,
        ageGroups: action.payload,
        loading: false,
      }

    case GET_AGEGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_AGEGROUP_DETAILS_SUCCESS:
      return {
        ...state,
        ageGroupDetails: action.payload,
        loading: false,
      }

    case GET_AGEGROUP_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_AGEGROUP_SUCCESS:
      return {
        ...state,
        ageGroups: [...state.ageGroups, action.payload],
        loading: false,
      }

    case CREATE_AGEGROUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_AGEGROUP_SUCCESS:
      return {
        ...state,
        ageGroups: [...state.ageGroups].map(ageGroup =>
          ageGroup._id.toString() === action.payload._id.toString()
            ? { ageGroup, ...action.payload }
            : ageGroup
        ),
        loading: false,
      }

    case UPDATE_AGEGROUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case DELETE_AGEGROUP_SUCCESS:
    //   return {
    //     ...state,
    //     ageGroups: state.ageGroups.filter(
    //       ageGroup => ageGroup._id.toString() !== action.payload._id.toString()
    //     ),
    //     loading: false,
    //   }

    case DELETE_AGEGROUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default AgeGroup
