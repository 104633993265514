import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import JoditEditor from "jodit-react"

const FormEditors = ({ content, setContent }) => {
  const editor = useRef(null)

  return (
    <Fragment>
      <div className="db__column">
        {/* <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          value={content ? content : ""}
        /> */}
        {/* <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={handleEditorChange}
          //   onChange={e => console.log(e)}
          value={content ? content : ""}
        /> */}

        <JoditEditor
          ref={editor}
          value={content}
          onChange={text => setContent(text)}
        />
      </div>
    </Fragment>
  )
}

export default FormEditors

FormEditors.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
}
