import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PROD_COLORS,
  ADD_NEW_COLOR,
  DELETE_COLOR,
  UPDATE_COLOR,
} from "./actionTypes"
import {
  getColorsSuccess,
  getColorsFail,
  addColorFail,
  addColorSuccess,
  updateColorSuccess,
  updateColorFail,
  deleteColorSuccess,
  deleteColorFail,
} from "./actions"

//Include Both Helper File with needed methods
// import { getCategories, getProjectsDetails, addNewProject, updateProject, deleteProject } from "helpers/fakebackend_helper"
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function colorApi() {
  return get("/color/all")
}

function addColorApi(color) {
  return post("/color/new", color)
}

function updateColorApi({ color, id }) {
  return ApiPut(`/color/${id}`, color)
}

function deleteColorApi(id) {
  return del(`/color/${id}`)
}

function* fetchColors() {
  try {
    const response = yield call(colorApi)
    yield put(getColorsSuccess(response))
  } catch (error) {
    yield put(getColorsFail(error))
  }
}

function* onAddNewColor({ payload: color }) {
  try {
    const response = yield call(addColorApi, color)
    yield put(addColorSuccess(response))
    Notification({
      type: "success",
      message: "Color Created Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(addColorFail(error))
  }
}

function* onUpdateColor({ payload }) {
  try {
    const response = yield call(updateColorApi, payload)
    yield put(updateColorSuccess(response))
    Notification({
      type: "success",
      message: "Color Updated Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(updateColorFail(error))
  }
}

function* onDeleteColor({ payload }) {
  try {
    const response = yield call(deleteColorApi, payload)
    yield put(deleteColorSuccess(response))
    Notification({
      type: "error",
      message: "Category Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteColorFail(error))
  }
}

function* colorSaga() {
  yield takeEvery(GET_PROD_COLORS, fetchColors)
  yield takeEvery(ADD_NEW_COLOR, onAddNewColor)
  yield takeEvery(UPDATE_COLOR, onUpdateColor)
  yield takeEvery(DELETE_COLOR, onDeleteColor)
}

export default colorSaga
