import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, CardSubtitle, Col, Container } from "reactstrap"
import { Row, TabContent, TabPane } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { getSubCategoryDetails, updateCategory } from "store/actions"
import LanguageTab from "components/Common/LanguageTab"
import ImageCard from "components/Common/ImageCardUpdate"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const UpdateSubCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { subCategoryDetails, loading } = useSelector(state => ({
    subCategoryDetails: state.SubCategory.subCategoryDetails,
    loading: state.SubCategory.loading,
  }))

  const [bannerImage, setBannerImages] = useState(subCategoryDetails?.images)
  const [imagesPreview, setImagesPreview] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const [logoImages, setLogoImages] = useState([])
  const [logoImagesPreview, setLogoImagesPreview] = useState([])

  function handleValidSubmit(values) {
    const updateSubCategoryData = {
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      priority: parseInt(values.priority),
      logoImages: logoImages,
      bannerImages: bannerImage,
      category: subCategoryDetails?.category,
    }
    dispatch(
      updateCategory(updateSubCategoryData, subCategoryDetails?._id, history)
    )
  }

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)

    setImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onChangeLogoImageHandler = e => {
    const files = Array.from(e.target.files)

    setLogoImagesPreview([])
    setLogoImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoImagesPreview(oldArray => [...oldArray, reader.result])
          setLogoImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    dispatch(getSubCategoryDetails(params?.id))
  }, [dispatch])

  const BannerImage = subCategoryDetails?.bannerImages
  const LogoImage = subCategoryDetails?.logoImages

  const onDeleteImg = id => {
    setImagesPreview(imagesPreview?.filter(item => item.id !== id))
  }

  const isMult = LanguageSwitch()?.isMult

  return (
    <>
      <MetaTag title={"Update SubCategory"} />

      <div className="page-content">
        <Breadcrumbs
          title="Classifications"
          breadcrumbItem="Update SubCategory"
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  <Card>
                    <CardBody>
                      <div className="col-3 mb-3 mx-3">
                        <AvField
                          name="priority"
                          label="Priority"
                          type="number"
                          value={
                            subCategoryDetails?.priority
                              ? subCategoryDetails?.priority
                              : ""
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody className="mx-3">
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}

                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    className=""
                                    label="Name"
                                    type="text"
                                    errorMessage="Invalid product name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      subCategoryDetails?.primaryLang?.name ??
                                      ""
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                    value={
                                      subCategoryDetails?.primaryLang
                                        ?.description ?? ""
                                    }
                                  />
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col xl="12">
                              <Row>
                                <Col className="col-12 d-flex flex-wrap">
                                  <Col className="col-12 d-flex flex-wrap">
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Name</CardSubtitle>
                                        <CardSubtitle>اسم</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.name"
                                        label=""
                                        type="text"
                                        value={
                                          subCategoryDetails?.secondaryLang
                                            ?.name ?? ""
                                        }
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Description</CardSubtitle>
                                        <CardSubtitle>وصف</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.description"
                                        label=""
                                        type="textarea"
                                        rows="7"
                                        value={
                                          subCategoryDetails?.secondaryLang
                                            ?.description ?? ""
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col className="col-12">
                      <ImageCard
                        LogoImage={LogoImage}
                        BannerImage={BannerImage}
                        onChangeImageHandler={onChangeImageHandler}
                        imagesPreview={imagesPreview}
                        logoImagesPreview={logoImagesPreview}
                        onChangeLogoImageHandler={onChangeLogoImageHandler}
                        onDeleteImg={onDeleteImg}
                      />
                    </Col>
                  </Row>
                  <div className="text-end mb-5 mt-2">
                    <button
                      type="submit"
                      className="btn px-5 btn-success save-customer"
                    >
                      {loading && (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        </>
                      )}
                      Save
                    </button>
                  </div>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateSubCategory
