import {
  GET_BRANDS,
  GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  GET_BRAND_DETAILS,
  GET_BRAND_DETAILS_SUCCESS,
  GET_BRAND_DETAILS_FAIL,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAIL,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND,
  UPDATE_BRAND_FAIL,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  CREATE_BRAND,
} from "./actionTypes"

const INIT_STATE = {
  brands: [],
  brandDetails: {},
  error: {},
  loading: false,
}

const Brand = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANDS:
    case GET_BRAND_DETAILS:
    case UPDATE_BRAND:
    case CREATE_BRAND:
      return {
        ...state,
        loading: true,
      }
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload,
        loading: false,
      }

    case GET_BRANDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BRAND_DETAILS_SUCCESS:
      return {
        ...state,
        brandDetails: action.payload,
        loading: false,
      }

    case GET_BRAND_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        brands: [...state.brands, action.payload],
        loading: false,
      }

    case CREATE_BRAND_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        brands: [...state.brands].map(brand =>
          brand._id.toString() === action.payload._id.toString()
            ? { brand, ...action.payload }
            : brand
        ),
        loading: false,
      }

    case UPDATE_BRAND_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case DELETE_BRAND_SUCCESS:
    //   return {
    //     ...state,
    //     brands: state.brands.filter(
    //       brand => brand._id.toString() !== action.payload._id.toString()
    //     ),
    //     loading: false,
    //   }

    case DELETE_BRAND_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Brand
