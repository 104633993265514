import {
  GET_PROD_COLORS,
  GET_PROD_COLORS_FAIL,
  GET_PROD_COLORS_SUCCESS,
  ADD_NEW_COLOR,
  ADD_COLOR_SUCCESS,
  ADD_COLOR_FAIL,
  UPDATE_COLOR,
  UPDATE_COLOR_SUCCESS,
  UPDATE_COLOR_FAIL,
  DELETE_COLOR,
  DELETE_COLOR_SUCCESS,
  DELETE_COLOR_FAIL,
} from "./actionTypes"

//all colors
export const getColors = () => ({
  type: GET_PROD_COLORS,
})

//all colors
export const getColorsSuccess = colors => ({
  type: GET_PROD_COLORS_SUCCESS,
  payload: colors,
})

export const getColorsFail = error => ({
  type: GET_PROD_COLORS_FAIL,
  payload: error,
})

export const addNewColor = color => ({
  type: ADD_NEW_COLOR,
  payload: color,
})

export const addColorSuccess = color => ({
  type: ADD_COLOR_SUCCESS,
  payload: color,
})

export const addColorFail = error => ({
  type: ADD_COLOR_FAIL,
  payload: error,
})

export const updateColor = (color, id) => ({
  type: UPDATE_COLOR,
  payload: {color, id},
})

export const updateColorSuccess = color => ({
  type: UPDATE_COLOR_SUCCESS,
  payload: color,
})

export const updateColorFail = error => ({
  type: UPDATE_COLOR_FAIL,
  payload: error,
})

export const deleteColor = id => ({
  type: DELETE_COLOR,
  payload: id,
})

export const deleteColorSuccess = color => ({
  type: DELETE_COLOR_SUCCESS,
  payload: color,
})

export const deleteColorFail = error => ({
  type: DELETE_COLOR_FAIL,
  payload: error,
})


