/* PROJECTS */
export const GET_PROD_COLORS = "GET_PROD_COLORS"
export const GET_PROD_COLORS_SUCCESS = "GET_PROD_COLORS_SUCCESS"
export const GET_PROD_COLORS_FAIL = "GET_PROD_COLORS_FAIL"

/**
 * add user
 */
 export const ADD_NEW_COLOR = "ADD_NEW_COLOR"
 export const ADD_COLOR_SUCCESS = "ADD_COLOR_SUCCESS"
 export const ADD_COLOR_FAIL = "ADD_COLOR_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_COLOR = "UPDATE_COLOR"
 export const UPDATE_COLOR_SUCCESS = "UPDATE_COLOR_SUCCESS"
 export const UPDATE_COLOR_FAIL = "UPDATE_COLOR_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_COLOR = "DELETE_COLOR"
 export const DELETE_COLOR_SUCCESS = "DELETE_COLOR_SUCCESS"
 export const DELETE_COLOR_FAIL = "DELETE_COLOR_FAIL"
