import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import Select from "react-select"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Container,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { getAllProducts, getEnquiryDetail, updateEnquiry } from "store/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"

const UpdateEnquiry = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { enquiryDetail, products } = useSelector(state => ({
    enquiryDetail: state.Enquiries.enquiryDetail,
    products: state.Products.products,
  }))

  const [searchText, setSearchText] = useState("")
  const [selectedProduct, setSelectedProduct] = useState("Search a Product")
  const [produtId, setProductId] = useState()

  function handleValidSubmit(values) {
    const updateEnquiryData = {
      email: values.email,
      name: values.full_name,
      phone: values.phone,
      note: values.note,
      status: values.status_value,
      product: produtId,
      quantity: 1,
    }
    dispatch(updateEnquiry(updateEnquiryData, params.id, history))
    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getEnquiryDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", searchText))
  }, [searchText])

  useEffect(() => {
    setSelectedProduct(enquiryDetail?.product?.primaryLang?.name)
  }, [])

  const statusTypes = ["new", "pending", "checked", "confirmed", "closed"]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerProductFinalValue(event) {
    setSelectedProduct(event?.label)
    setProductId(event?.value)
  }

  const productOptions = [
    {
      options:
        products?.length &&
        products?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
        })),
    },
  ]

  return (
    <>
      <MetaTag title="Update Enquiry" />

      <div className="page-content">
        <Breadcrumbs title="Enquiries" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update Enquiry</CardTitle>

                    <p>
                      Enquiry for{" "}
                      <CardTitle>
                        {enquiryDetail?.product?.primaryLang?.name}
                      </CardTitle>
                    </p>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Col className="mb-3">
                        <CardTitle
                          className="h4 mb-2 "
                          style={{ color: "#1e81b0" }}
                        >
                          Products
                        </CardTitle>
                        <FormGroup className="mb-3">
                          <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleEnters}
                              value={selectedProduct}
                              placeholder={selectedProduct}
                              onChange={handlerProductFinalValue}
                              options={productOptions}
                              classNamePrefix="select-selection"
                              isLoading={true}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="name"
                            label="Full Name"
                            type="text"
                            value={enquiryDetail?.name}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter full name",
                              },
                            }}
                          />
                        </Col>
                        <Col>
                          <AvField
                            name="phone"
                            label="Phone Number"
                            type="number"
                            value={enquiryDetail?.phone}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter phone number",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="email"
                            label="Email"
                            type="text"
                            value={enquiryDetail?.email}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter email",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="note"
                            label="Note"
                            type="textarea"
                            value={enquiryDetail?.note}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="status_value"
                            label="Status"
                            type="select"
                            value={enquiryDetail?.status}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please select a status",
                              },
                            }}
                          >
                            <option>Choose a status ...</option>
                            {statusTypes.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateEnquiry

UpdateEnquiry.propTypes = {
  history: PropTypes.object,
}
