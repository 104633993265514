import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Product
import Products from "./products/reducer"

//shopProduct
import ShopProducts from "./shopProducts/reducer"

// //shop
import Shops from "./shops/reducer"

//Category
import Category from "./category/reducer"

//SubCategory
import SubCategory from "./subCategory/reducer"

//AgeGroup
import AgeGroup from "./ageGroup/reducer"

//Brand
import Brand from "./brand/reducer"

//GenderType
import GenderType from "./genderType/reducer"

//Classifications
import Classifications from "./classifications/reducer"

//user
import Users from "./users/reducer"

//order
import Orders from "./orders/reducer"

//Color
import Color from "./color/reducer"

// Settings
import Settings from "./settings/reducer"

// Notifications
import Notifications from "./notification/reducer"

// Revenues
import Revenues from "./revenues/reducer"

//Banner
import Banner from "./banner/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//colletions
import Collection from "./collections/reducer"

//groups
import Group from "./groups/reducer"

//coupon
import Coupon from "./coupon/reducer"

//area
import Area from "./area_list/reducer"

//enquire
import Enquiries from "./enquiry/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Products,
  ShopProducts,
  Shops,
  Settings,
  Notifications,
  Revenues,

  Category,
  SubCategory,
  AgeGroup,
  Brand,
  GenderType,
  Banner,
  Classifications,
  Collection,
  Group,
  Coupon,
  Area,
  Enquiries,

  Users,
  Orders,
  Dashboard,
  Color,
})

export default rootReducer
