import {
    GET_ENQUIRIES,
    GET_ENQUIRIES_SUCCESS,
    GET_ENQUIRIES_FAIL,
    GET_ENQUIRIES_CARDS,
    GET_ENQUIRIES_CARDS_SUCCESS,
    GET_ENQUIRIES_CARDS_FAIL,
    GET_ENQUIRY_DETAIL,
    GET_ENQUIRY_DETAIL_SUCCESS,
    GET_ENQUIRY_DETAIL_FAIL,
    CREATE_ENQUIRY,
    CREATE_ENQUIRY_SUCCESS,
    CREATE_ENQUIRY_FAIL,
    UPDATE_ENQUIRY,
    UPDATE_ENQUIRY_SUCCESS,
    UPDATE_ENQUIRY_FAIL,
    DELETE_ENQUIRY,
    DELETE_ENQUIRY_SUCCESS,
    DELETE_ENQUIRY_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    enquiries: [],
    enquiriesCards: {},
    enquiryDetail: {},
    error: {},
    loading: false,
    detailLoading: false,
    createdEnquiry: {},
  }
  
  const Enquiries = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ENQUIRIES:
      case GET_ENQUIRIES_CARDS:
      case UPDATE_ENQUIRY:
      case CREATE_ENQUIRY:
      case DELETE_ENQUIRY:
        return {
          ...state,
          loading: true,
        }
      case GET_ENQUIRY_DETAIL:
        return {
          ...state,
          loading: true,
          detailLoading: true,
        }

      case GET_ENQUIRIES_SUCCESS:
        return {
          ...state,
          enquiries: action.payload,
          loading: false,
        }
      case GET_ENQUIRIES_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }

      case GET_ENQUIRIES_CARDS_SUCCESS:
        return {
          ...state,
          enquiriesCards: action.payload,
          loading: false,
        }
      case GET_ENQUIRIES_CARDS_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }

      case CREATE_ENQUIRY_SUCCESS:
        return {
          ...state,
          // states: action.payload,
          createdEnquiry: action.payload,
          loading: false,
        }
  
      case CREATE_ENQUIRY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
      case GET_ENQUIRY_DETAIL_SUCCESS:
        return {
          ...state,
          enquiryDetail: action.payload,
          loading: false,
          detailLoading: false,
        }
  
      case GET_ENQUIRY_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
          detailLoading: false,
        }
  
      case UPDATE_ENQUIRY_SUCCESS:
        return {
          ...state,
          enquiryDetail: action.payload,
          loading: false,
        }
  
      case UPDATE_ENQUIRY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
      case DELETE_ENQUIRY_SUCCESS:
        return {
          ...state,
          // states: state.states.filter(
          //   state => state.id.toString() !== action.payload.id.toString()
          // ),
          loading: false,
        }
  
      case DELETE_ENQUIRY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
      default:
        return state
    }
  }
  
  export default Enquiries
  