import { call, put, takeEvery } from "redux-saga/effects"

import { 
  GET_SHOP_PRODUCTS, 
  GET_DELETED_SHOP_PRODUCTS, 
  GET_SHOP_PRODUCT_DETAILS,
  CREATE_SHOP_PRODUCT, 
  UPDATE_SHOP_PRODUCT, 
  CHANGE_SHOP_PRODUCT_STATUS, 
  DELETE_SHOP_PRODUCT,
  HARD_DELETE_SHOP_PRODUCT,
  RESTORE_SHOP_PRODUCT
} from "./actionTypes"
import {
  getShopProductsSuccess,
  getShopProductsFail,
  getDeletedShopProductsSuccess,
  getDeletedShopProductsFail,
  getShopProductDetailsSuccess,
  getShopProductDetailsFail,
  createShopProductFail,
  createShopProductSuccess,
  updateShopProductSuccess,
  updateShopProductFail,
  changeShopProductStatusSuccess,
  changeShopProductStatusFail,
  deleteShopProductSuccess,
  deleteShopProductFail,
  hardDeleteShopProductSuccess,
  hardDeleteShopProductFail,
  restoreShopProductSuccess,
  restoreShopProductFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import {Notification} from "../../components/Common/Notification"


// function getZestonProductsAPi({ searchText, page }) {
//   if (searchText) {
//     return get(`/zestonProduct/all?search=${searchText && searchText}`)
//   } else {
//     return get(`/zestonProduct/all?page=${page ? page : 1}&limit=10`)
//   }
// }

function getShopProductsAPi({page}) {
  return get(`/shopProduct/all?page=${page ? page : 1}&limit=10`)
}

function getDeletedShopProductsAPi() {
  return get("/shopProduct/deleted/all")
}

const getShopProductDetailsAPi = shopProductId => {
  return get(`/shopProduct/${shopProductId}`)
}

const createShopProductApi = ({shopProduct}) => {
  return post("/shopProduct/new", shopProduct)
}

const updateShopProductApi = ({ shopProduct, shopProductId  }) => {
  return ApiPut(`/shopProduct/${shopProductId}`, shopProduct)
}

const changeShopProductStatusApi = ({ shopProductId  }) => {
  return post(`/shopProduct/status/${shopProductId}`)
}

const deleteShopProductApi = (shopProductId) => {
  return del(`/shopProduct/${shopProductId}`)
}

const hardDeleteShopProductApi = shopProductId => {
  return del(`/shopProduct/hard/${shopProductId}`)
}

const restoreShopProductApi = shopProductId => {
  return post(`/shopProduct/restore/${shopProductId}`)
}

function* fetchShopProducts({payload}) {
  try {
    const response = yield call(getShopProductsAPi, payload)
    yield put(getShopProductsSuccess(response))
  } catch (error) {
    yield put(getShopProductsFail(error))
  }
}

function* fetchDeletedShopProducts() {
  try {
    const response = yield call(getDeletedShopProductsAPi)
    yield put(getDeletedShopProductsSuccess(response))
  } catch (error) {
    yield put(getDeletedShopProductsFail(error))
  }
}

function* fetchShopProductDetail({ payload: shopProductId }) {
  try {
    const response = yield call(getShopProductDetailsAPi, shopProductId)
    yield put(getShopProductDetailsSuccess(response))
  } catch (error) {
    yield put(getShopProductDetailsFail(error))
  }
}

function* onCreateShopProduct({ payload }) {
  try {
    const response = yield call(createShopProductApi, payload)
    yield put(createShopProductSuccess(response))
    Notification({
      type: "success",
      message: "ShopProduct Created Successfully!",
      title: ""
    })
    payload.history.goBack();
  } catch (error) {
    yield put(createShopProductFail(error))
  }
  
}

function* onUpdateShopProduct({ payload }) {
  try {
    Notification({
      type: "success",
      message: "ShopProduct Updated Successfully!",
      title: ""
    })
    const response = yield call(updateShopProductApi, payload)
    yield put(updateShopProductSuccess(response))
    
    payload.history.goBack();
  } catch (error) {
    yield put(updateShopProductFail(error))
  }
}

function* onChangeShopProductStatus({ payload }) {
  try {
    const response = yield call(changeShopProductStatusApi, payload)
    yield put(changeShopProductStatusSuccess(response))
    Notification({
      type: "success",
      message: "ShopProduct Status Changed Successfully!",
      title: ""
    })
  } catch (error) {
    yield put(changeShopProductStatusFail(error))
  }
}
// { shopProductId, history 
function* onDeleteShopProduct({ shopProductId, history }) {
  try {
    const response = yield call(deleteShopProductApi, shopProductId)
    yield put(deleteShopProductSuccess(response))
    Notification({
      type: "error",
      message: "ShopProduct Deleted Successfully!",
      title: ""
    })
    // history.goBack();
  } catch (error) {
    yield put(deleteShopProductFail(error))
  }
}

function* onHardDeleteShopProduct({ shopProductId, history }) {
  try {
    const response = yield call(hardDeleteShopProductApi, shopProductId)
    yield put(hardDeleteShopProductSuccess(response))
    Notification({
      type: "error",
      message: "ShopProduct Hard Deleted Successfully!",
      title: ""
    })
    history.goBack();
  } catch (error) {
    yield put(hardDeleteShopProductFail(error))
  }
}

function* onRestoreShopProduct({ shopProductId, history }) {
  try {
    const response = yield call(restoreShopProductApi, shopProductId)
    yield put(restoreShopProductSuccess(response))
    Notification({
      type: "success",
      message: "ShopProduct Restored Successfully!",
      title: ""
    })
    history.goBack();
  } catch (error) {
    yield put(restoreShopProductFail(error))
  }
}

function* shopProductSaga() {
  yield takeEvery(GET_SHOP_PRODUCTS, fetchShopProducts)
  yield takeEvery(GET_DELETED_SHOP_PRODUCTS, fetchDeletedShopProducts)
  yield takeEvery(GET_SHOP_PRODUCT_DETAILS, fetchShopProductDetail)
  yield takeEvery(CREATE_SHOP_PRODUCT, onCreateShopProduct)
  yield takeEvery(UPDATE_SHOP_PRODUCT, onUpdateShopProduct)
  yield takeEvery(CHANGE_SHOP_PRODUCT_STATUS, onChangeShopProductStatus)
  yield takeEvery(DELETE_SHOP_PRODUCT, onDeleteShopProduct)
  yield takeEvery(HARD_DELETE_SHOP_PRODUCT, onHardDeleteShopProduct)
  yield takeEvery(RESTORE_SHOP_PRODUCT, onRestoreShopProduct)
}

export default shopProductSaga
