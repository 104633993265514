import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,

  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  settings: {},
  privacy: {},
  error: {},
}

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        privacy: action.payload.legal.privacyPolicy
      }

    case GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload
      }
    
    case UPDATE_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    
    default:
      return state
  }
}

export default Settings
