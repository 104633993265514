import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  CREATE_AREA_LIST,
  DELETE_AREA_LIST,
  GET_AREA_LISTS,
  GET_AREA_LIST_DETAIL,
  UPDATE_AREA_LIST,
} from "./actionTypes"
import {
  createArea_listFail,
  createArea_listSuccess,
  deleteArea_listFail,
  deleteArea_listSuccess,
  getArea_listsDtailsFail,
  getArea_listsDtailsSuccess,
  getArea_listsFail,
  getArea_listsSuccess,
  updateArea_listFail,
  updateArea_listSuccess,
} from "./actions"

import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function area_listsApi() {
  return get("/area/admin/all")
}

const getAreaDetailsAPi = area_listId => {
  return get(`/area/admin/${area_listId}`)
}

function createAreaApi({ area_list }) {
  return post("/area/admin/new", area_list)
}

function updateAreaApi({ area_list, area_listId }) {
  return ApiPut(`/area/admin/${area_listId}`, area_list)
}

function deleteAreaApi(area_listId) {
  return del(`/area/admin/${area_listId}`)
}

function* fetchAreas() {
  try {
    const response = yield call(area_listsApi)
    yield put(getArea_listsSuccess(response))
  } catch (error) {
    yield put(getArea_listsFail(error))
  }
}

function* fetchAreaDetails({ payload: area_listId }) {
  try {
    const response = yield call(getAreaDetailsAPi, area_listId)
    yield put(getArea_listsDtailsSuccess(response))
  } catch (error) {
    yield put(getArea_listsDtailsFail(error))
  }
}

function* onCreateAreaList({ payload }) {
  try {
    const response = yield call(createAreaApi, payload)
    yield put(createArea_listSuccess(response))
    Notification({
      type: "success",
      message: "Area Created Successfully!",
      title: "",
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createArea_listFail(error.response.data))
  }
}

function* onUpdateArea({ payload }) {
  try {
    const response = yield call(updateAreaApi, payload)
    yield put(updateArea_listSuccess(response))
    Notification({
      type: "success",
      message: "Area Updated Successfully!",
      title: "",
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateArea_listFail(error))
  }
}

function* onDeleteArea({ area_listId }) {
  try {
    const response = yield call(deleteAreaApi, area_listId)
    yield put(deleteArea_listSuccess(response))
    Notification({
      type: "success",
      message: "Area Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteArea_listFail(error))
  }
}

function* areaSaga() {
  yield takeEvery(GET_AREA_LISTS, fetchAreas)
  yield takeEvery(GET_AREA_LIST_DETAIL, fetchAreaDetails)
  yield takeEvery(CREATE_AREA_LIST, onCreateAreaList)
  yield takeEvery(UPDATE_AREA_LIST, onUpdateArea)
  yield takeEvery(DELETE_AREA_LIST, onDeleteArea)
}

export default areaSaga
