import { call, put, takeEvery } from "redux-saga/effects"

// Settings Redux States
import { GET_SETTINGS, UPDATE_SETTINGS } from "./actionTypes"
import {
  getSettingsSuccess,
  getSettingsFail,
  updateSettingsSuccess,
  updateSettingsFail,
} from "./actions"

//Include Both Helper File with needed methods
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function settingsApi() {
  return get("/settings")
}

function updateSettingsApi({ settings, id }) {
  return ApiPut(`/settings/${id}`, settings)
}

function* fetchSettings() {
  try {
    const response = yield call(settingsApi)
    yield put(getSettingsSuccess(response))
  } catch (error) {
    yield put(getSettingsFail(error))
  }
}

function* onUpdateSettings({ payload }) {
  try {
    const response = yield call(updateSettingsApi, payload)
    yield put(updateSettingsSuccess(response))
    Notification({
      type: "success",
      message: "Settings Updated Successfully!",
      title: "",
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateSettingsFail(error))
  }
}

function* settingsSaga() {
  yield takeEvery(GET_SETTINGS, fetchSettings)
  yield takeEvery(UPDATE_SETTINGS, onUpdateSettings)
}

export default settingsSaga
