import toastr from "toastr"
import "toastr/build/toastr.min.css"

export function Notification({ type, title, message, timeOut }) {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: timeOut ? timeOut : type === "error" ? 3000 : 2000,
    extendedTimeOut: 500,
    // closeButton: closeButton,
    // debug: debug,
    // progressBar: progressBar,
    preventDuplicates: "preventDuplicates",
    newestOnTop: "newestOnTop",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 300,
  }

  if (type === "info") toastr.info(message, title)
  else if (type === "warning") toastr.warning(message, title)
  else if (type === "error") toastr.error(message, title)
  else toastr.success(message, title)

  return true
}

export const errorNotification = msg => {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
