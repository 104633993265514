import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import "../../App.css"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getSettings } from "store/actions"

import {
  getColors as onGetColors,
  addNewColor as onAddNewColor,
  updateColor as onUpdateColor,
  deleteColor as onDeleteColor,
} from "store/color/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import MetaTag from "components/Common/Meta-tag"

const Colors = props => {
  const dispatch = useDispatch()

  const { colors } = useSelector(state => ({
    colors: state.Color.colors,
  }))

  const [modal, setModal] = useState(false)
  const [colorList, setColorList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetColors())
  }, [dispatch])

  useEffect(() => {
    setColorList(colors)
  }, [colors])

  useEffect(() => {
    if (!isEmpty(colors)) {
      setColorList(colors)
    }
  }, [colors])

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: colors?.length, // replace later with size(orders),
    custom: true,
  }

  const EcommerceCustomerColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "value",
      text: "Color",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div
            className={"avatar-title rounded-circle bg-soft"}
            style={{
              backgroundColor: `${row.value}`,
              width: "30px",
              height: "30px",
              boxShadow: "0px 0px 5px #888888",
            }}
          ></div>
          {row.value}
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, color) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleColorClick(color)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteColor(color._id)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const newColors = colors?.data?.map(item => ({
    ...item,
    name: item?.name,
    id: item?._id,
    description: item?.description,
  }))

  const toggle = () => {
    setModal(!modal)
  }

  const handleColorClick = arg => {
    const color = arg

    setColorList({
      id: color.id,
      name: color.name,
      value: color.value,
    })

    setIsEdit(true)
    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const handleDeleteColor = id => {
    dispatch(onDeleteColor(id))
    onPaginationPageChange(1)
  }

  const handleValidColorSubmit = (e, values) => {
    if (isEdit) {
      dispatch(onUpdateColor(values, colorList.id))
    } else {
      dispatch(onAddNewColor(values))
    }

    toggle()
  }
  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setColorList(
      colors.filter(color =>
        Object.keys(color).some(key =>
          color[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleColorClicks = () => {
    setColorList("")
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title={"Colors"} />

        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="All Colors" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={EcommerceCustomerColumns}
                    data={newColors}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={newColors || []}
                        columns={EcommerceCustomerColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleColorClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Color
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal
                                  isOpen={modal}
                                  toggle={toggle}
                                  className="modal-md"
                                >
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Color" : "Add Color"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <AvForm
                                      onValidSubmit={(e, v) =>
                                        handleValidColorSubmit(e, v)
                                      }
                                    >
                                      <Row form>
                                        <Col className="p-3">
                                          <div className="col-12 mb-3">
                                            <AvField
                                              name="value"
                                              label="Value"
                                              type="color"
                                              errorMessage="Invalid description"
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={colorList?.value || ""}
                                            />
                                          </div>

                                          <div className="col-8 mb-3">
                                            <AvField
                                              name="name"
                                              label="Name"
                                              type="text"
                                              errorMessage="Invalid color name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={colorList?.name || ""}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-customer"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Colors.propTypes = {
  colors: PropTypes.array,
  onGetColors: PropTypes.func,
  onAddNewColor: PropTypes.func,
  onDeleteColor: PropTypes.func,
  onUpdateColor: PropTypes.func,
}

export default Colors
