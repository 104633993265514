import Select from "react-select"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { TabContent, TabPane, FormGroup } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import { CardSubtitle } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import LanguageTab from "components/Common/LanguageTab"
import ImageCard from "components/Common/ImageCardCreate"

//actions
import { createSubCategory, getCategories } from "store/actions"
import { LanguageSwitch } from "common/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

const CrateSubCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { loading, categories } = useSelector(state => ({
    loading: state.SubCategory.loading,
    categories: state.Category.categories,
  }))

  const [logoImages, setLogoImages] = useState([])
  const [logoImagesPreview, setLogoImagesPreview] = useState([])

  const [bannerImages, setBannerImages] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const [searchText, setSearchText] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("Search a Category")
  const [selectedCategoryForApi, setSelectedCategoryForApi] =
    useState("Search a Category")

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch, searchText])

  function handleValidSubmit(values) {
    const createSubcategory = {
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      priority: parseInt(values.priority),
      logoImages: logoImages,
      bannerImages: bannerImages,
      category: selectedCategoryForApi,
    }
    dispatch(createSubCategory(createSubcategory, history))
  }

  const onChangeLogoImageHandler = e => {
    const files = Array.from(e.target.files)

    setLogoImagesPreview([])
    setLogoImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoImagesPreview(oldArray => [...oldArray, reader.result])
          setLogoImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const onChangeBannerImageHandler = e => {
    const files = Array.from(e.target.files)

    setBannerImagesPreview([])
    setBannerImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBannerImagesPreview(oldArray => [
            ...oldArray,
            { url: reader.result, id: oldArray.length + 1 },
          ])
          setBannerImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerCategoryFinalValue(event) {
    setSelectedCategory(event.label)
    setSelectedCategoryForApi(event.value)
  }

  const categoryOptions = [
    {
      options:
        categories?.length &&
        categories?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
        })),
    },
  ]

  const onDeleteImg = id => {
    setBannerImagesPreview(bannerImagesPreview?.filter(item => item.id !== id))
  }

  const isMult = LanguageSwitch()?.isMult

  return (
    <>
      <MetaTag title="Create Subcategory" />

      <div className="page-content">
        <Breadcrumbs title="Subcategory" breadcrumbItem="Create Subcategory" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"
                        style={{ color: "#1e81b0" }}
                      ></CardTitle>
                      <Row>
                        <Col lg={4} md={6} sm={12} xl={4}>
                          <CardTitle
                            className="h4 mb-4 "
                            style={{ color: "#1e81b0" }}
                          >
                            Priority
                          </CardTitle>
                          <div className="mb-3">
                            <AvField name="priority" label="" type="number" />
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xl={4} className="mb-3">
                          <CardTitle
                            className="h4 mb-4 "
                            style={{ color: "#1e81b0" }}
                          >
                            Category
                          </CardTitle>
                          <FormGroup className="mb-3">
                            <div className="col-md-12"></div>
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleEnters}
                                value={selectedCategory}
                                placeholder={selectedCategory}
                                onChange={handlerCategoryFinalValue}
                                options={categoryOptions}
                                classNamePrefix="select2-selection"
                                isLoading={true}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}

                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    label="Name"
                                    type="text"
                                    errorMessage="Invalid product name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                  />
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col xl="12">
                              <Row>
                                <Col className="col-12 d-flex flex-wrap">
                                  <Col className="col-12 d-flex flex-wrap">
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Name</CardSubtitle>
                                        <CardSubtitle>اسم</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.name"
                                        label=""
                                        type="text"
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        <CardSubtitle>Description</CardSubtitle>
                                        <CardSubtitle>وصف</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: "right" }}
                                        name="secondaryLang.description"
                                        label=""
                                        type="textarea"
                                        rows="7"
                                      />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  <ImageCard
                    onDeleteImg={onDeleteImg}
                    bannerImagesPreview={bannerImagesPreview}
                    logoImagesPreview={logoImagesPreview}
                    onChangeBannerImageHandler={onChangeBannerImageHandler}
                    onChangeLogoImageHandler={onChangeLogoImageHandler}
                  />
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              )}
                              Create Subcategory
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CrateSubCategory
