import {
  GET_REVENUES_FAIL,
  GET_REVENUES_SUCCESS,

  GET_REVENUE_DETAILS,
  GET_REVENUE_DETAILS_SUCCESS,
  GET_REVENUE_DETAILS_FAIL,

} from "./actionTypes"

const INIT_STATE = {
  revenues: {},
  revenueDetails: {},
  error: {},
}

const Revenues = (state = INIT_STATE, action) => {
  switch (action.type) {

    
    case GET_REVENUE_DETAILS:
      return {
        ...state,
        loading: true,
      }


    case GET_REVENUES_SUCCESS:
      return {
        ...state,
        revenues: action.payload,
      }

    case GET_REVENUES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    
    case GET_REVENUE_DETAILS_SUCCESS:
      return {
        ...state,
        revenueDetails: action.payload,
        loading: false,
      }

    case GET_REVENUE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    
    default:
      return state
  }
}

export default Revenues
