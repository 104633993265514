import {
  GET_SUBCATEGORIES,
  GET_SUBCATEGORIES_FAIL,
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORY_DETAILS,
  GET_SUBCATEGORY_DETAILS_SUCCESS,
  GET_SUBCATEGORY_DETAILS_FAIL,
  CREATE_SUBCATEGORY,
  CREATE_SUBCATEGORY_SUCCESS,
  CREATE_SUBCATEGORY_FAIL,
  UPDATE_SUBCATEGORY,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
} from "./actionTypes"

//all subCategories
export const getSubCategories = () => ({
  type: GET_SUBCATEGORIES,
})

//all subCategories
export const getSubCategoriesSuccess = subCategories => ({
  type: GET_SUBCATEGORIES_SUCCESS,
  payload: subCategories,
})

export const getSubCategoriesFail = error => ({
  type: GET_SUBCATEGORIES_FAIL,
  payload: error,
})

export const getSubCategoryDetails = subCategoryId => ({
  type: GET_SUBCATEGORY_DETAILS,
  payload: subCategoryId,
})

export const getSubCategoryDetailsSuccess = subCategoryDetails => ({
  type: GET_SUBCATEGORY_DETAILS_SUCCESS,
  payload: subCategoryDetails,
})

export const getSubCategoryDetailsFail = error => ({
  type: GET_SUBCATEGORY_DETAILS_FAIL,
  payload: error,
})

export const createSubCategory = (subCategory, history) => ({
  type: CREATE_SUBCATEGORY,
  payload: {subCategory, history},
})

export const createSubCategorySuccess = subCategory => ({
  type: CREATE_SUBCATEGORY_SUCCESS,
  payload: subCategory
})

export const createSubCategoryFail = error => ({
  type: CREATE_SUBCATEGORY_FAIL,
  payload: error,
})

export const updateSubCategory = (subCategory, subCategoryId, history) => ({
  type: UPDATE_SUBCATEGORY,
  payload: {subCategory, subCategoryId, history},
})

export const updateSubCategorySuccess = subCategory => ({
  type: UPDATE_SUBCATEGORY_SUCCESS,
  payload: subCategory,
})

export const updateSubCategoryFail = error => ({
  type: UPDATE_SUBCATEGORY_FAIL,
  payload: error,
})

export const deleteSubCategory = (subCategoryId, history) => ({
  type: DELETE_SUBCATEGORY, 
  subCategoryId, history
})

export const deleteSubCategorySuccess = subCategory => ({
  type: DELETE_SUBCATEGORY_SUCCESS,
  payload: subCategory,
})

export const deleteSubCategoryFail = error => ({
  type: DELETE_SUBCATEGORY_FAIL,
  payload: error,
})


