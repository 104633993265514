import {
  GET_PROD_COLORS_FAIL,
  GET_PROD_COLORS_SUCCESS,
  ADD_COLOR_SUCCESS,
  ADD_COLOR_FAIL,
  UPDATE_COLOR_SUCCESS,
  UPDATE_COLOR_FAIL,
  DELETE_COLOR_SUCCESS,
  DELETE_COLOR_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  colors: [],
  error: {},
}

const Color = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROD_COLORS_SUCCESS:
      return {
        ...state,
        colors: action.payload,
      }

    case GET_PROD_COLORS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_COLOR_SUCCESS:
      return {
        ...state,
        colors: {data:[...state.colors.data, action.payload.data]}
      }
    
    case ADD_COLOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case UPDATE_COLOR_SUCCESS:
      return {
        ...state,
        colors: {
          data: state.colors.data.map(color =>
            color._id === action.payload.data._id
              ? action.payload.data
              : color
          ),
        }
      }
    
    case UPDATE_COLOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COLOR_SUCCESS:
      return {
        ...state,
        colors: {
          data: state.colors.data.filter(
            color => color._id !== action.payload.data._id
          ),
        }
      }

    case DELETE_COLOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Color
