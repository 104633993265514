import {
    GET_ENQUIRIES,
    GET_ENQUIRIES_SUCCESS,
    GET_ENQUIRIES_FAIL,
    GET_ENQUIRIES_CARDS,
    GET_ENQUIRIES_CARDS_SUCCESS,
    GET_ENQUIRIES_CARDS_FAIL,
    GET_ENQUIRY_DETAIL,
    GET_ENQUIRY_DETAIL_SUCCESS,
    GET_ENQUIRY_DETAIL_FAIL,
    CREATE_ENQUIRY,
    CREATE_ENQUIRY_SUCCESS,
    CREATE_ENQUIRY_FAIL,
    UPDATE_ENQUIRY,
    UPDATE_ENQUIRY_SUCCESS,
    UPDATE_ENQUIRY_FAIL,
    DELETE_ENQUIRY,
    DELETE_ENQUIRY_SUCCESS,
    DELETE_ENQUIRY_FAIL,
  } from "./actionTypes"
  
  export const getEnquiries = (searchText, page) => ({
    type: GET_ENQUIRIES,
    payload: { searchText, page },
  })
  export const getEnquiriesSuccess = enquiries => ({
    type: GET_ENQUIRIES_SUCCESS,
    payload: enquiries,
  })
  export const getEnquiriesFail = error => ({
    type: GET_ENQUIRIES_FAIL,
    payload: error,
  })

  export const getEnquiriesCards = () => ({
    type: GET_ENQUIRIES_CARDS,
  })
  export const getEnquiriesCardsSuccess = enquiriesCards => ({
    type: GET_ENQUIRIES_CARDS_SUCCESS,
    payload: enquiriesCards,
  })
  export const getEnquiriesCardsFail = error => ({
    type: GET_ENQUIRIES_CARDS_FAIL,
    payload: error,
  })
  
  export const getEnquiryDetail = enquiryId => ({
    type: GET_ENQUIRY_DETAIL,
    enquiryId,
  })
  
  export const getEnquiryDetailSuccess = enquiryDetail => ({
    type: GET_ENQUIRY_DETAIL_SUCCESS,
    payload: enquiryDetail,
  })
  
  export const getEnquiryDetailFail = error => ({
    type: GET_ENQUIRY_DETAIL_FAIL,
    payload: error,
  })
  
  export const createEnquiry = (enquiry, history) => ({
    type: CREATE_ENQUIRY,
    payload: { enquiry, history },
  })
  
  export const createEnquirySuccess = enquiry => ({
    type: CREATE_ENQUIRY_SUCCESS,
    payload: enquiry,
  })
  
  export const createEnquiryFail = error => ({
    type: CREATE_ENQUIRY_FAIL,
    payload: error,
  })
  
  export const updateEnquiry = (enquiry, enquiryId, history) => ({
    type: UPDATE_ENQUIRY,
    payload: { enquiry, enquiryId, history },
  })
  
  export const updateEnquirySuccess = enquiry => ({
    type: UPDATE_ENQUIRY_SUCCESS,
    payload: enquiry,
  })
  
  export const updateEnquiryFail = error => ({
    type: UPDATE_ENQUIRY_FAIL,
    payload: error,
  })
  
  export const deleteEnquiry = (enquiryId, history) => ({
    type: DELETE_ENQUIRY,
    payload: { enquiryId, history },
  })
  
  export const deleteEnquirySuccess = enquiry=> ({
    type: DELETE_ENQUIRY_SUCCESS,
    payload: enquiry,
  })
  
  export const deleteEnquiryFail = error => ({
    type: DELETE_ENQUIRY_FAIL,
    payload: error,
  })
  