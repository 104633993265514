import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Extras
import Colors from "pages/Colors/Colors"

//Notifications
import Notifications from "pages/Notification/index"

//ZestonProduct
import Product from "pages/Product/index"
import DeletedProduct from "pages/Product/Deleted/index"
import ProductDetails from "pages/Product/SingleView/index"
import UpdateProduct from "pages/Product/Crud/Update/Update"
import CreateProduct from "pages/Product/Crud/Create/Create"

//ShopProduct
import ShopProduct from "pages/ShopProduct/index"
import DeletedShopProduct from "pages/ShopProduct/Deleted/index"
import ShopProductDetails from "pages/ShopProduct/SingleView/index"
import UpdateShopProduct from "pages/ShopProduct/Crud/Update/Update"
import CreateShopProduct from "pages/ShopProduct/Crud/Create/Create"

//Shop
import UpdateShop from "pages/Shop/Crud/Update/Update"
import Shop from "pages/Shop/index"
import ShopDetails from "pages/Shop/SingleView/index"
import QuarentinedShop from "pages/Shop/QuarantinedShops/index"
import UnapprovedShop from "pages/Shop/UnapprovedShops/index"
import CreateShop from "pages/Shop/Crud/Create/Create"

//Category
// import Categories from "pages/Category/index"
import UpdateCategory from "pages/Classifications/Category/Crud/Update"
import CreateCategory from "pages/Classifications/Category/Crud/Create"

//Banner
import Banners from "pages/Banner/index"
import UpdateBanner from "pages/Banner/Crud/Update/Update"
import CreateBanner from "pages/Banner/Crud/Create/Create"

//User
import User from "pages/User/index"
import UserDetails from "pages/User/SingleView/index"
import UpdateUser from "pages/User/Crud/Update/Update"

//Order
import Orders from "pages/Order/index"
import OrderDetails from "pages/Order/SingleView/index"
import StarredOrders from "pages/Order/StarredOrder/index"
import CancelledOrders from "pages/Order/CancelledOrder/index"
import ReturnedOrders from "pages/Order/ReturnedOrder/index"

//Settings
import Settingsd from "pages/Settings/index"
import UpdateSettings from "pages/Settings/Crud/Update/Update"

//Revenue
import Revenues from "pages/Revenue/index"
import RevenueDetail from "pages/Revenue/shopDetail/index"

//Errors
//404
import Pages404 from "pages/error/pages-404"
import Classification from "pages/Classifications/index"
import UpdateBrand from "pages/Classifications/Brand/Crud/Update"
import CreateGenderType from "pages/Classifications/GenderType/Crud/Create"
import UpdateGenderType from "pages/Classifications/GenderType/Crud/Update"
import CreateAgeGroup from "pages/Classifications/AgeGroup/Crud/Create"
import CrateSubCategory from "pages/Classifications/SubCategory/Crud/Create"
import Collections from "pages/collections"
import CrateCollections from "pages/collections/Crud/Create"
import UpdateCollections from "pages/collections/Crud/Update"
import UpdateSubCategory from "pages/Classifications/SubCategory/Crud/Update"
import CreateBrand from "pages/Classifications/Brand/Crud/Create"
import UpdateAgeGroup from "pages/Classifications/AgeGroup/Crud/Update"
import Groups from "pages/groups-home"
import CrateGroups from "pages/groups-home/Crud/Create"
import UpdateGroups from "pages/groups-home/Crud/Update"
import Coupons from "pages/coupon"
import CreateCoupon from "pages/coupon/Crud/Create"
import UpdateCoupons from "pages/coupon/Crud/Update"
import AreaList from "pages/Area"
import Enquiries from "pages/Enquiries"
import UpdateEnquiry from "pages/Enquiries/Crud/Update"
import CreateEnquiry from "pages/Enquiries/Crud/Create"

const authProtectedRoutes = [
  //enquiries
  { path: "/enquiries", component: Enquiries },
  { path: "/enquiry/update/:id", component: UpdateEnquiry },
  { path: "/enquiry/create", component: CreateEnquiry },

  // products
  { path: "/products", component: Product },
  { path: "/products/deleted", component: DeletedProduct },
  { path: "/product/:id", component: ProductDetails },
  { path: "/product/create/new", component: CreateProduct },
  { path: "/product/update/:id", component: UpdateProduct },

  // shopProducts
  { path: "/shopproducts", component: ShopProduct },
  { path: "/shopproducts/deleted", component: DeletedShopProduct },
  { path: "/shopproduct/:id", component: ShopProductDetails },
  { path: "/shopproduct/create/new", component: CreateShopProduct },
  { path: "/shopproduct/update/:id", component: UpdateShopProduct },

  //shops
  { path: "/shops", component: Shop },
  { path: "/shops/quarentined", component: QuarentinedShop },
  { path: "/shops/unapproved", component: UnapprovedShop },
  { path: "/shop/:id", component: ShopDetails },
  { path: "/shop/create/new", component: CreateShop },
  { path: "/shop/update/:id", component: UpdateShop },

  //Classifications
  { path: "/classifications", component: Classification },

  //Category
  { path: "/category/create/new", component: CreateCategory },
  { path: "/category/update/:id", component: UpdateCategory },

  //sub category
  { path: "/subcategory/create/new", component: CrateSubCategory },
  { path: "/subcategory/update/:id", component: UpdateSubCategory },

  //Brand
  { path: "/brand/create/new", component: CreateBrand },
  { path: "/brand/update/:id", component: UpdateBrand },

  //gendertype
  { path: "/genderType/create/new", component: CreateGenderType },
  { path: "/genderType/update/:id", component: UpdateGenderType },

  //genderType
  { path: "/agegroup/create/new", component: CreateAgeGroup },
  { path: "/agegroup/update/:id", component: UpdateAgeGroup },

  //collections
  { path: "/collections", component: Collections },
  { path: "/collections/create/new", component: CrateCollections },
  { path: "/collections/update/:id", component: UpdateCollections },

  //groups
  { path: "/groups", component: Groups },
  { path: "/groups/create/new", component: CrateGroups },
  { path: "/groups/update/:id", component: UpdateGroups },

  //coupons
  { path: "/coupons", component: Coupons },
  { path: "/coupons/create/new", component: CreateCoupon },
  { path: "/coupons/update/:id", component: UpdateCoupons },

  //area
  { path: "/area", component: AreaList },

  // Users
  { path: "/users", component: User },
  { path: "/users/:id", component: UserDetails },
  { path: "/user/update/:id", component: UpdateUser },

  // Orders
  { path: "/orders", component: Orders },
  { path: "/order/:id", component: OrderDetails },
  { path: "/orders/starred", component: StarredOrders },
  { path: "/orders/cancelled", component: CancelledOrders },
  { path: "/orders/returned", component: ReturnedOrders },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //Colors
  { path: "/colors", component: Colors },

  //Notifications
  { path: "/notifications", component: Notifications },

  //Banner
  { path: "/banners", component: Banners },
  { path: "/banner/create/new", component: CreateBanner },
  { path: "/banner/update/:id", component: UpdateBanner },

  //Settings
  { path: "/superadmin/settings", component: Settingsd },
  { path: "/superadmin/settings/:id", component: UpdateSettings },

  //Revenues
  { path: "/revenues", component: Revenues },
  { path: "/revenue/:id", component: RevenueDetail },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  //errors
  //404
  { path: "/404", component: Pages404 },
]

export { publicRoutes, authProtectedRoutes }
