import {
  GET_AGEGROUPS,
  GET_AGEGROUPS_FAIL,
  GET_AGEGROUPS_SUCCESS,
  GET_AGEGROUP_DETAILS,
  GET_AGEGROUP_DETAILS_SUCCESS,
  GET_AGEGROUP_DETAILS_FAIL,
  CREATE_AGEGROUP,
  CREATE_AGEGROUP_SUCCESS,
  CREATE_AGEGROUP_FAIL,
  UPDATE_AGEGROUP,
  UPDATE_AGEGROUP_SUCCESS,
  UPDATE_AGEGROUP_FAIL,
  DELETE_AGEGROUP,
  DELETE_AGEGROUP_SUCCESS,
  DELETE_AGEGROUP_FAIL,
} from "./actionTypes"

//all ageGroups
export const getAgeGroups = () => ({
  type: GET_AGEGROUPS,
})

export const getAgeGroupsSuccess = ageGroups => ({
  type: GET_AGEGROUPS_SUCCESS,
  payload: ageGroups,
})

export const getAgeGroupsFail = error => ({
  type: GET_AGEGROUPS_FAIL,
  payload: error,
})

export const getAgeGroupDetails = ageGroupId => ({
  type: GET_AGEGROUP_DETAILS,
  payload: ageGroupId,
})

export const getAgeGroupDetailsSuccess = ageGroupDetails => ({
  type: GET_AGEGROUP_DETAILS_SUCCESS,
  payload: ageGroupDetails,
})

export const getAgeGroupDetailsFail = error => ({
  type: GET_AGEGROUP_DETAILS_FAIL,
  payload: error,
})

export const createAgeGroup = (ageGroup, history) => ({
  type: CREATE_AGEGROUP,
  payload: {ageGroup, history},
})

export const createAgeGroupSuccess = ageGroup => ({
  type: CREATE_AGEGROUP_SUCCESS,
  payload: ageGroup
})

export const createAgeGroupFail = error => ({
  type: CREATE_AGEGROUP_FAIL,
  payload: error,
})

export const updateAgeGroup = (ageGroup, ageGroupId, history) => ({
  type: UPDATE_AGEGROUP,
  payload: {ageGroup, ageGroupId, history},
})

export const updateAgeGroupSuccess = ageGroup => ({
  type: UPDATE_AGEGROUP_SUCCESS,
  payload: ageGroup,
})

export const updateAgeGroupFail = error => ({
  type: UPDATE_AGEGROUP_FAIL,
  payload: error,
})

export const deleteAgeGroup = (ageGroupId, history) => ({
  type: DELETE_AGEGROUP, 
  ageGroupId, history
})

export const deleteAgeGroupSuccess = ageGroup => ({
  type: DELETE_AGEGROUP_SUCCESS,
  payload: ageGroup,
})

export const deleteAgeGroupFail = error => ({
  type: DELETE_AGEGROUP_FAIL,
  payload: error,
})


