import { call, put, takeEvery } from "redux-saga/effects"

// Settings Redux States
import { GET_REVENUES, GET_REVENUE_DETAILS } from "./actionTypes"
import {
  getRevenuesSuccess,
  getRevenuesFail,

  getRevenueDetailsSuccess,
  getRevenueDetailsFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import { post, get, ApiPut, del } from "../../helpers/api_helper"
import {Notification} from "../../components/Common/Notification"

function revenuesApi({page}) {
  return get(`/static/revenue?page=${page ? page : 1}&limit=10`)
}

const getRevenueDetailsAPi = ({revenueId}) => {
  return get(`/static/revenue/shop/${revenueId}`)
  
}


function* fetchRevenues({payload}) {
  try {
    const response = yield call(revenuesApi, payload)
    yield put(getRevenuesSuccess(response))
  } catch (error) {
    yield put(getRevenuesFail(error))
  }
}

function* fetchRevenueDetail({ payload: revenueId }) {
  try {
    const response = yield call(getRevenueDetailsAPi, revenueId)
    yield put(getRevenueDetailsSuccess(response))
  } catch (error) {
    yield put(getRevenueDetailsFail(error))
  }
}

function* revenuesSaga() {
  yield takeEvery(GET_REVENUES, fetchRevenues)
  yield takeEvery(GET_REVENUE_DETAILS, fetchRevenueDetail)

}

export default revenuesSaga
