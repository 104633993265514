import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

//i18n
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {
  const dispatch = useDispatch()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [all, setAll] = useState(false)

  //notification
  const { notifications, loading } = useSelector(state => ({
    notifications: state.Notifications.notifications.notifications,
    loading: state?.Notifications?.loading,
  }))

  useEffect(() => {
    // dispatch(getNotifications())
  }, [dispatch])

  const parent = notifications?.slice(0, 3)

  const getAll = () => {
    if (all) {
      return notifications?.slice(0, 10)
    } else {
      return parent
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {getAll()?.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small" onClick={() => setAll(!all)}>
                  {" "}
                  {all ? "Show Less" : "Show All"}
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: all ? "400px" : "230px" }}>
            {getAll()?.map((item, key) => (
              <Fragment key={key}>
                <Link to="#" className="text-reset notification-item">
                  <div className="media">
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{item.title}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{item.message}</p>
                        <p>{item.user?.name}</p>
                        <p>
                          <i className="mdi mdi-clock-outline" />{" "}
                          {moment(item.createdAt).fromNow() == "8 days ago"
                            ? moment(item.createdAt).format(
                                "h:mm a | MMM/DD/YYYY"
                              )
                            : moment(item.updatedAt).fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </Fragment>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              onClick={() => setAll(!all)}
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {all ? "Show Less" : "Show All"}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
